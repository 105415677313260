import { XMarkIcon } from '@heroicons/react/24/solid';
import { toaster } from 'baseui/toast';
import ContentError from './content-error';

type TErrorCode =
  | 'error_client'
  | 'error_server'
  | 'error_general'
  | 'error_maintenance';

export const showGlobalAlert = (key: TErrorCode) => {
  const ERROR_CONTENT = {
    error_client: {
      imageSrc: 'https://assets.mobitech.id/icons/error-client.webp',
      title: 'Ada Sedikit Kesalahan',
      subTitle:
        'Cek pengaturan dan koneksi internet, kosongkan cache, dan coba lagi, atau buka dengan browser lain.',
    },
    error_server: {
      imageSrc: 'https://assets.mobitech.id/icons/error-server.png',
      title: 'Maaf, Ada Kesalahan Teknis',
      subTitle:
        'Maaf, terdapat kesalahan teknis dan kami sedang mencoba memperbaikinya. Coba akses halaman ini lagi nanti.',
    },
    error_general: {
      imageSrc: 'https://assets.mobitech.id/icons/error-general.png',
      title: 'Ada Masalah Server',
      subTitle:
        'Server mengalami kesalahan untuk sementara dan tidak dapat menyelesaikan permintaan Anda. Cobalah beberapa saat lagi.',
    },
    error_maintenance: {
      imageSrc: 'https://assets.mobitech.id/icons/error-general.png',
      title: '',
      subTitle:
        'Maaf, sedang dalam proses perbaikan. Untuk info lebih lanjut, silakan hubungi whatsapp +6281113607111 atau email ke care@mobitech.id',
    },
  };

  toaster.info(
    <ContentError
      title={ERROR_CONTENT?.[key].title}
      subTitle={ERROR_CONTENT?.[key].subTitle}
      imageSrc={ERROR_CONTENT?.[key].imageSrc}
    />,
    {
      key,
      closeable: true,
      autoHideDuration: 0,
      overrides: {
        Body: {
          style: {
            position: 'relative',
            background: 'white',
            width: '500px',
            marginTop: '8%',
            zIndex: 9999,
          },
        },
        InnerContainer: {
          style: { width: '100%' },
        },
        CloseIcon: {
          component: () => {
            return (
              <div className="absolute top-4 right-4">
                <XMarkIcon
                  role="button"
                  onClick={() => toaster.clear(key)}
                  height={24}
                  width={24}
                  cursor="pointer"
                  color="black"
                />
              </div>
            );
          },
        },
      },
    }
  );
};
