/* eslint-disable @typescript-eslint/no-explicit-any */
// to get all colors existing in base ui
import type { Theme } from 'baseui';
import { createTheme as createThemeBase } from 'baseui';
import { DeepPartial, Grid, MakeExtendable } from 'baseui/styles/types';
import { colors } from 'baseui/tokens';
import { Client as Styletron } from 'styletron-engine-monolithic';

export const engine = new Styletron();
export const defaultTheme = {
  colors: {
    // Primary
    primary: '#03222F',
    primaryA: '#03222F',
    primaryC: '#1E64DC',
    accent: '#1E64DC',
    blue700: '#1E64DC',
    red: '#DE1135',

    // Button
    buttonPrimaryFill: '#1E64DC',
    buttonPrimaryActive: '#1E64DC',
    buttonPrimaryHover: colors.blue500,

    // Link
    linkText: '#1E64DC',
    linkVisited: '#1E64DC',
    linkHover: '#1E64DC',
    linkActive: '#1E64DC',

    // Toast
    toastInfoBackground: '#4B4B4B',
    toastPositiveBackground: '#EAF6ED',
    toastWarningBackground: '#FDF2DC',
    toastNegativeBackground: '#FFF0EE',

    inputPlaceholder: '#A6A6A6',

    bannerActionLowWarning: '#FDF2DC',
    bannerActionHighWarning: '#FDF2DC',
  },

  grid: {
    maxWidth: '100dvh',
  } as DeepPartial<MakeExtendable<Grid>> | any,
};

export const createTheme = (parameters?: Theme) => {
  const theme = createThemeBase({
    ...defaultTheme,
    ...parameters,
  });

  return theme;
};
