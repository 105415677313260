/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { isEmpty } from '../utils';
import { indexedDBStorage, TOptionsIdb } from './indexed-db-storage';

type TIndexedDBProvider = {
  children: ReactNode;
  opt: TOptionsIdb;
  isAuthPage?: boolean;
};

type TValueProvider = {
  db: IDBDatabase | null;
  error: any;
  dataDBUser: any;
  setDataDBUser: (data: any) => void;
};

const IndexedDBContext = createContext<TValueProvider>({
  db: null,
  error: null,
  dataDBUser: {},
  setDataDBUser: () => {},
});

export const IndexedDBProvider = ({
  children,
  opt,
  isAuthPage,
}: TIndexedDBProvider) => {
  const { init } = indexedDBStorage(opt);
  const [db, setDb] = useState<IDBDatabase | null>(null);
  const [error, setError] = useState(null);
  const [dataDBUser, setDataDBUser] = useState<any>({});

  useEffect(() => {
    let isMounted = true;
    init()
      .then((database) => {
        if (isMounted) {
          setDb(database);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setError(err);
        }
      });
    const handleVisibilityChange = () => {
      if (document.hidden && !isEmpty(db)) {
        db?.close();
        setDb(null);
      }
    };

    if (!isAuthPage) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      db?.close();
      setDb(null);
      setDataDBUser({});
      isMounted = false;
      if (!isAuthPage) {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
      }
    };
  }, []);

  return (
    <IndexedDBContext.Provider value={{ db, error, dataDBUser, setDataDBUser }}>
      {children}
    </IndexedDBContext.Provider>
  );
};

export const useIndexedDBProvider = () => useContext(IndexedDBContext);
