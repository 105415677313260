import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { useWindowSize, withErrorBoundary } from '@navi-app/utils';
import { Image } from '@unpic/react';
import { ARTWORK_TYPE, Banner } from 'baseui/banner';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ROLE, SIZE } from 'baseui/modal';
import { HeadingMedium, ParagraphMedium } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import useUpdateVersion from './hooks/use-update-version';

function ArtworkIcon({ size }: { size: string }) {
  return <InformationCircleIcon className="w-6 h-6" />;
}

function ModalUpdateVersion() {
  const { t } = useTranslation('modal-update-version');

  const { onUpdateVersion, closeModal, isOpen } = useUpdateVersion();

  const { isPhoneSize } = useWindowSize();

  return (
    <Modal
      onClose={closeModal}
      closeable={false}
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Dialog: {
          style: ({ $theme }) => ({
            marginTop: isPhoneSize ? '72px' : '16px',
            marginRight: isPhoneSize ? '-56px' : '16px',
            marginLeft: isPhoneSize ? '-56px' : '16px',
            width: isPhoneSize ? '80%' : '500px',
          }),
        },
      }}
    >
      <ModalBody>
        <div className="flex flex-col items-center mt-16">
          <Image
            src="https://assets.mobitech.id/images/illustration-new-version.svg"
            alt="illustration-new-version"
            width={247}
            height={177}
          />
          <HeadingMedium className="!mt-4 text-center">
            {t('title')}
          </HeadingMedium>
          <ParagraphMedium className="!mt-2 text-center">
            {t('description')}
          </ParagraphMedium>
        </div>

        <Banner
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                margin: '32px 0px 16px 0px',
              }),
            },
          }}
          artwork={{
            icon: ArtworkIcon,
            type: ARTWORK_TYPE.badge,
          }}
        >
          {t('info_message')}
        </Banner>

        <Button
          className="!w-full !text-base lg:!text-lg"
          type="submit"
          onClick={onUpdateVersion}
        >
          {t('update_now')}
        </Button>
      </ModalBody>
    </Modal>
  );
}

export default withErrorBoundary(ModalUpdateVersion);
