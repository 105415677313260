/* eslint-disable @typescript-eslint/no-explicit-any */
import { TIMEZONE_WIB, TIMEZONE_WIT, TIMEZONE_WITA } from '@navi-app/constants';
import clsx, { ClassValue } from 'clsx';
import { format, isValid } from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
import { enUS, id } from 'date-fns/locale';
import i18next from 'i18next';
import { twMerge } from 'tailwind-merge';

export function cn(...classes: ClassValue[]) {
  return twMerge(clsx(classes));
}

export const isEmpty = (obj: any) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const get = (
  obj: any,
  path: string,
  defaultValue = undefined as any
) => {
  const travel = (regexp: any) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const isBrowserCached = () => {
  if ('caches' in window) {
    caches.match(window.location.href).then(function (response) {
      if (response) {
        return true;
      } else {
        return false;
      }
    });
  } else {
    // Browser does not support the caches API
    return false;
  }
};

const dateFnsLocales = { id, en: enUS };

export const formatDateToLocal = (
  dateValue: string | Date,
  dateFormat: string = 'dd MMM yyyy',
  zone?: typeof TIMEZONE_WIB | typeof TIMEZONE_WITA | typeof TIMEZONE_WIT,
  defaultValue = '-'
) => {
  if (!isValid(new Date(dateValue)) || !dateValue) {
    return defaultValue;
  }
  const lang = i18next?.language || 'id'; // switch to id
  const locale = dateFnsLocales[lang as keyof typeof dateFnsLocales];

  if (zone) {
    const zonedDate = utcToZonedTime(dateValue, zone);
    return format(zonedDate, dateFormat, { locale });
  }

  return format(new Date(dateValue), dateFormat, { locale });
};

export const formatDatePayload = (
  date: string | Date,
  pattern = "yyyy-MM-dd'T'HH:mm:ss'Z'",
  defaultValue = '-'
) => {
  if (!isValid(new Date(date)) || !date) {
    return defaultValue;
  }
  if (date) {
    const zonedDate = utcToZonedTime(date, TIMEZONE_WIB);
    const dateValue = formatTz(zonedDate, pattern, {
      timeZone: TIMEZONE_WIB,
    });
    return dateValue;
  }
  return null;
};

export const cleanObject = (obj: any): any => {
  const cleanedObj: any = {};

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      cleanedObj[key] = obj[key];
    }
  }

  return cleanedObj;
};

export const cloneDeep = (obj: any) => JSON.parse(JSON.stringify(obj));

export function toPascalCaseWithSpaces(inputString = '') {
  return inputString
    .replace(/_/g, ' ')
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (match, index) => match.toUpperCase())
    .replace(/\s+/g, ' ');
}

export function isValidUrl(url: string): boolean {
  try {
    const urlObject = new URL(url);
    return urlObject.protocol !== null && urlObject.host !== null;
  } catch (error) {
    return false;
  }
}

export const cleanEmptyKeyObject = (obj: any) => {
  const cloneObj = structuredClone(obj);
  Object.keys(cloneObj).map((key) => {
    if (
      cloneObj[key] === '' ||
      isEmpty(cloneObj[key]) ||
      cloneObj[key] === null
    ) {
      delete cloneObj[key];
    }
    return key;
  });
  return cloneObj;
};

export const isLengthValid = (text: string, length: number) => {
  return text.length >= length;
};

export const hasUpperCase = (text: string) => {
  return /[A-Z]/.test(text);
};

export const hasNumber = (text: string) => {
  return /[0-9]/.test(text);
};

export const hasSymbol = (text: string) => {
  return /[\W_]/.test(text);
};

export function encodeString(input: string) {
  return btoa(input);
}

export function decodeString(input: string) {
  return atob(input);
}

export function isValidBase64String(input: string) {
  const base64regex =
    /^(?:[A-Za-z0-9+/]{4})*?(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  return base64regex.test(input);
}
export const removeWords = (inputString: string, wordsToRemove: string[]) => {
  wordsToRemove?.forEach((word: string) => {
    inputString = inputString?.replace(new RegExp(word, 'gi'), '');
  });
  return inputString?.trim();
};

export const convertMinutesToHours = (minutes: number) => {
  if (typeof minutes !== 'number' || minutes === 0) {
    return '-';
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} jam ${remainingMinutes} menit`;
};

export const formatStringToNumber = (str: string) =>
  str?.replace(/\./g, '').replace(',', '.');

export const formatConvertToComa = (str: string) =>
  str?.replace(',', '').replace('.', ',');

export const formatConvertToFullStop = (str: string) => str.replace(',', '.');

export const removeLeadingZeros = (value: string) => {
  if (value.length > 1) {
    value = value.replace(/^0+/, '');
  }
  return value;
};

export const formatStringToDecimal = (str: string) => {
  const [before, after] = str.split(',') || ['', ''];
  let decimal = after?.replace(/\./g, ''); // delete thousands separator

  let newValue = removeLeadingZeros(before?.replace(/\./g, '')); // delete thousands separator & leading zeros
  newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // re-add formatting thousand separator for returned value

  if (decimal) {
    decimal = decimal.replace(/0+$/, '');
  }
  return decimal ? `${newValue},${decimal}` : newValue;
};

export const formatNumberToString = (number = 0, maxDecimal = 3) =>
  new Intl.NumberFormat('id-ID', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDecimal,
  }).format(number);

export const formatNumberInput = (num: string) => {
  // Split the number into integer and decimal parts
  const parts = num.split(',');
  let integerPart = parts[0];
  const decimalPart = parts.length > 1 ? ',' + parts[1] : '';

  // Remove any non-digit characters (except for commas)
  integerPart = integerPart.replace(/\D/g, '');

  // Add dot separator for thousands
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return integerPart + decimalPart;
};

export const getTimeZoneAbbreviation = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneAbbreviation =
    timeZone === TIMEZONE_WIB
      ? 'WIB'
      : timeZone === TIMEZONE_WITA
      ? 'WITA'
      : timeZone === TIMEZONE_WIT
      ? 'WIT'
      : timeZone;
  return timeZoneAbbreviation;
};

export const formatDateWithTimeZone = (
  dateStr: string,
  isShowTimezone = true
) => {
  if (dateStr) {
    const date = new Date(dateStr);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const zonedDate = utcToZonedTime(date, timeZone);
    const formattedDate = formatTz(zonedDate, 'dd MMM yyyy, HH:mm', {
      timeZone,
    });

    const timeZoneAbbreviation =
      timeZone === TIMEZONE_WIB
        ? 'WIB'
        : timeZone === TIMEZONE_WITA
        ? 'WITA'
        : timeZone === TIMEZONE_WIT
        ? 'WIT'
        : timeZone;

    const dateResult = `${formattedDate} ${timeZoneAbbreviation}`;
    if (!isShowTimezone || !formattedDate || !timeZoneAbbreviation) {
      return format(new Date(dateStr), 'dd MMM yyyy, HH:mm', { locale: id });
    } else {
      return dateResult;
    }
  }
  return dateStr;
};

export const toFixed = (x: any) => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
};

export const slicedDecimal = (temp: string, decimal = 5) => {
  if (temp) {
    const [before, after] = temp.split('.') || ['', ''];
    const slicedDecimal = after?.slice(0, decimal);

    const calculate = slicedDecimal ? `${before},${slicedDecimal}` : before;

    return calculate;
  }
  return temp;
};

export const convertHoursMinutesToMinutes = (
  hours: number,
  minutes: number
) => {
  return hours * 60 + minutes;
};

export const randomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const formattingDecimalInter = (
  num: string | number,
  minDecimal = 0,
  maxDecimal = 100
) => {
  const userLocale = navigator.language || 'id-ID';

  // Ensure the input is treated as a number
  const number =
    typeof num === 'string'
      ? Number(num.replace(/,/g, '')) // Replace commas in the string before converting to number
      : num;

  // Round the number to the maximum decimal places
  const roundedNumber = parseFloat(number.toFixed(maxDecimal));

  // Define locale-based options
  const options = {
    style: 'decimal',
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal,
  };

  // Create a formatter based on the locale
  const formatter = new Intl.NumberFormat(userLocale, options);

  // Format the number
  return formatter.format(roundedNumber);
};

export const convertToMinutes = (hours: number, minutes: number) => {
  // Calculate total minutes
  const totalMinutes = Number(hours) * 60 + Number(minutes);
  return totalMinutes;
};

export const convertTimeToFullDate = (time: string) => {
  const date = new Date('2024-08-19T00:00:00.000Z');
  const offset = new Date().getTimezoneOffset();
  const diffHours = Math.floor(Math.abs(offset) / 60);
  const sign = offset <= 0 ? '+' : '-';

  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(':').map(Number);

  // Set the hours and minutes on the Date object
  date.setUTCHours(
    sign === '+' ? hours - diffHours : hours + diffHours,
    minutes,
    0,
    0
  );

  // Get the full date-time string with the current time zone
  const fullDateTimeString = date.toString();

  return fullDateTimeString;
};

export const convertTimeToWIB = (time: string) => {
  const date = new Date('2024-08-19T00:00:00.000Z');
  // Split the time string into hours and minutes
  if (!time) {
    return '';
  }

  const [hours, minutes] = time.split(':').map(Number);

  // Set the hours and minutes on the Date object
  date.setUTCHours(hours, minutes, 0, 0);

  // Convert the date to the Asia/Jakarta time zone (GMT+7)
  const zonedDate = utcToZonedTime(date, TIMEZONE_WIB);

  // Format the date to a string with the desired format
  const formatHourMinutes = formatTz(zonedDate, 'HH:mm', {
    timeZone: TIMEZONE_WIB,
  });

  return formatHourMinutes;
};

export const getDateTime = (time: string) => {
  if (time?.length) {
    const [hours, minutes] = time.split(':').map(Number);

    // Create a new Date object with today's date
    const today = new Date();

    // Set the hours and minutes to the specified time
    return today.setHours(hours, minutes, 0, 0);
  }

  return time;
};

export const getValue = (obj: Record<string, any>, field: string) => {
  const fieldComponents = field.split('.');
  let value: any = obj;

  for (const component of fieldComponents) {
    if (value && Object.prototype.hasOwnProperty.call(value, component)) {
      value = value[component];
    } else {
      return undefined;
    }
  }
  return value;
};

export const timeStringToDate = (timeString: string | undefined) => {
  if (!timeString) {
    return '';
  }
  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  const date = new Date();

  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  date.setMilliseconds(0); // Reset milliseconds
  date.setUTCHours(hours, minutes, 0, 0); // Adjust for GMT+0700

  return date;
};
