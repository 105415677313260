import { useWindowSize, withErrorBoundary } from '@navi-app/utils';
import { Skeleton } from 'baseui/skeleton';

function LoadingState() {
  const { isPhoneSize } = useWindowSize();
  return (
    <div>
      <section className="bg-gray-100 rounded-2xl flex lg:flex-row flex-col lg:gap-[60px]">
        <Skeleton
          height="500px"
          width={isPhoneSize ? '380px' : '400px'}
          animation
          autoSizeRows
        />
        <div className="pr-10 py-10 flex flex-col gap-4 pl-4 lg:pl-0">
          <div className="flex items-center gap-3">
            <Skeleton
              rows={1}
              height="30px"
              width="80px"
              animation
              autoSizeRows
            />
            <Skeleton
              rows={1}
              height="30px"
              width="120px"
              animation
              autoSizeRows
            />
          </div>
          <Skeleton
            rows={5}
            width={isPhoneSize ? '300px' : '800px'}
            animation
          />
          <div className="flex items-center gap-2">
            <Skeleton
              rows={1}
              height="60px"
              width="180px"
              animation
              autoSizeRows
            />
            <Skeleton
              rows={1}
              height="60px"
              width="80px"
              animation
              autoSizeRows
            />
          </div>
        </div>
      </section>
      <section className="mt-6 lg:mt-[60px]">
        <div className="flex justify-between">
          <Skeleton
            rows={1}
            height="40px"
            width={isPhoneSize ? '200px' : '500px'}
            animation
            autoSizeRows
          />

          <Skeleton
            rows={1}
            height="40px"
            width={isPhoneSize ? '80px' : '100px'}
            animation
            autoSizeRows
          />
        </div>

        <div className="mt-7 grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-[30px] justify-items-center bg-gray-100 py-5">
          {Array.from({ length: 3 }).map((item, index) => (
            <div className="flex flex-col gap-2">
              <Skeleton
                height="246px"
                width={isPhoneSize ? '350px' : '500px'}
                animation
                autoSizeRows
              />
              <Skeleton
                rows={3}
                height="70px"
                width={isPhoneSize ? '350px' : '500px'}
                animation
                autoSizeRows
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default withErrorBoundary(LoadingState);
