/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { withErrorBoundary } from '@navi-app/utils';
import { Navigation } from 'baseui/side-navigation';
import { TFunction } from 'i18next';
import { ReactNode, forwardRef, useEffect, useState } from 'react';

export type DynamicObject = {
  [name: string]: any;
};

type TSideNavigation = {
  t: TFunction;
  defaultUrl: string;
  defaultActiveItem: string;
  sectionRefs: DynamicObject;
  cardDatas: {
    id: string;
    ref: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    children: ReactNode;
  }[];
  customClassCardWrapper?: string;
  customClass?: string;
};

type TProps = {
  children: ReactNode;
  id?: string;
};

const Sidebar = ({
  sectionRefs,
  defaultUrl,
  defaultActiveItem,
  navigationItems,
}: any) => {
  const [activeItemId, setActiveItemId] = useState<string | undefined>(
    `${defaultUrl}#${defaultActiveItem}`
  );

  useEffect(() => {
    const sections = Object?.keys(sectionRefs.current);

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveItemId(`${defaultUrl}#${entry.target.id}`);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    sections.forEach((section) => {
      if (sectionRefs.current[section]) {
        observer.observe(sectionRefs.current[section]);
      }
    });

    return () => {
      sections.forEach((section) => {
        if (sectionRefs.current[section]) {
          observer.unobserve(sectionRefs.current[section]);
        }
      });
    };
  }, [sectionRefs]);

  return (
    <Navigation
      items={navigationItems}
      activeItemId={activeItemId}
      onChange={({ item }) => setActiveItemId(item.itemId)}
    />
  );
};

const SectionWrapper = forwardRef((props: any, ref) => (
  <div
    id={props?.id}
    // @ts-expect-error
    ref={ref}
  >
    {props?.children}
  </div>
));

const Card = (props: TProps) => {
  return (
    <div className="border border-[#E8E8E8] rounded-xl p-6" id={props?.id}>
      {props?.children}
    </div>
  );
};

const SideNavigationComponent: React.FC<TSideNavigation> = ({
  t,
  defaultUrl,
  sectionRefs,
  defaultActiveItem,
  cardDatas,
  customClassCardWrapper,
  customClass,
}: TSideNavigation) => {
  const navigationItems = cardDatas?.map((c: any) => {
    return {
      title: t(c?.id),
      itemId: `${defaultUrl}#${c?.id}`,
    };
  });

  return (
    <div className={`${customClass || 'flex flex-col md:flex-row gap-4'}`}>
      {/* Sidebar Navigation */}
      <div className="w-full md:w-[230px] md:flex-shrink-0 md:flex md:flex-col">
        <Sidebar
          sectionRefs={sectionRefs}
          defaultUrl={defaultUrl}
          defaultActiveItem={defaultActiveItem}
          navigationItems={navigationItems}
        />
      </div>
      {/* Content Section */}
      <div
        className={
          customClassCardWrapper ||
          'flex flex-col flex-1 gap-4 overflow-auto no-scrollbar scroll-smooth'
        }
      >
        {cardDatas?.map((c: any) => (
          <SectionWrapper
            id={c.id}
            // @ts-ignore
            ref={c.ref}
          >
            <Card>{c.children}</Card>
          </SectionWrapper>
        ))}
      </div>
    </div>
  );
};

export default withErrorBoundary(SideNavigationComponent);
