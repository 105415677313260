import {
  useModalStore,
  useWindowSize,
  withErrorBoundary,
} from '@navi-app/utils';
import { Modal, ModalBody } from 'baseui/modal';

type TModalTutorialVideo = {
  tutorialUrl?: string;
};

const ModalTutorialVideo = ({ tutorialUrl }: TModalTutorialVideo) => {
  const { modalName, openModal, modalData, closeModal } = useModalStore();

  const { isPhoneSize } = useWindowSize();

  const onClose = () => {
    if (modalData?.isFromNavbar) {
      closeModal();
    } else {
      openModal('modal-welcome-onboard');
    }
  };

  return (
    <Modal
      isOpen={modalName === 'modalTutorialVideo'}
      onClose={onClose}
      overrides={{
        Dialog: {
          style: {
            marginTop: isPhoneSize ? '68px' : '16px',
            marginRight: isPhoneSize ? '-56px' : '16px',
            marginLeft: isPhoneSize ? '-56px' : '16px',
            width: isPhoneSize ? '80%' : '90%',
            maxWidth: '800px',
            minWidth: '300px',
            height: '500px',
          },
        },
      }}
    >
      <ModalBody className="pt-2 h-full">
        <iframe
          title="tutorial-video"
          src={tutorialUrl || ''}
          style={{
            width: '100%',
            minHeight: '450px',
            height: '83.33%',
            marginTop: '12px',
          }}
          allowFullScreen
        />
      </ModalBody>
    </Modal>
  );
};

export default withErrorBoundary(ModalTutorialVideo);
