/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, SIZE } from 'baseui/button';
import { Spinner } from 'baseui/spinner';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

type Tfallback = {
  error?: any;
  resetErrorBoundary?: (x?: any) => void;
  onClickAction?: (x?: any) => void;
  onReset?: (x?: any) => void;
  onClearCache?: any;
};

type TTypeAdditional = {
  isPage?: boolean;
  onReset?: (x?: any) => void;
  onClickAction?: (x?: any) => void;
  customFallBackRender?: any;
  customFallBackSuspense?: React.ReactNode;
  logError?: (x?: any) => void;
};

const descriptionError =
  'Maaf, server tidak dapat memahami permintaan dari browser Anda. Periksa pengaturan dan koneksi internet, kosongkan cache, buka dengan browser lain, dan coba lagi.';

const onClearCache = () => {
  try {
    if ('caches' in window) {
      console.info('clear cache...');
      caches?.keys()?.then((names) => {
        // Delete all the cache files
        names?.forEach((name) => {
          caches?.delete(name);
        });
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-empty
  }
};

function fallbackComponent({
  error,
  resetErrorBoundary,
  onClickAction,
  onReset,
}: Tfallback) {
  return (
    <div className="flex text-red-700 bg-red-100 p-3">
      {error?.message || 'Something went wrong'}
    </div>
  );
}

function fallbackPage({
  error,
  resetErrorBoundary,
  onClickAction,
  onReset,
}: Tfallback) {
  return (
    <div className="p-5 flex items-center justify-center">
      <div
        className="flex flex-col items-center justify-center p-5 pb-5 border-2 border-red-400 rounded-md shadow-lg"
        style={{ height: 'auto' }}
      >
        <img
          src="https://s3.ap-southeast-1.amazonaws.com/waresix.com/assets/illustration-v3/new-error-400.svg"
          alt="Error Page"
          width={400}
          height={300}
        />
        <div className="flex flex-col items-center justify-center">
          <div className="text-blue-900 text-3xl font-bold my-2">
            Ada Masalah
          </div>
          <div className="text-grey-800 pt-2 text-center max-w-4xl mb-4">
            {descriptionError}
          </div>
          <Button
            size={SIZE.compact}
            className="!w-full !py-4 !text-xl"
            onClick={(x) => {
              try {
                if (resetErrorBoundary) {
                  onClearCache();
                  setTimeout(() => {
                    resetErrorBoundary(x);
                  }, 350);
                }

                if (onClickAction) {
                  setTimeout(() => {
                    onClickAction(x);
                  }, 600);
                }
              } catch (err: any) {
                // eslint-disable-next-line no-empty
              }
            }}
          >
            Kembali ke Halaman Utama
          </Button>
        </div>
      </div>
    </div>
  );
}

type DynamicObj = {
  [x: string]: any;
};

export function withErrorBoundary<T extends DynamicObj>(
  Component: React.ComponentType<T>,
  additionalProps: TTypeAdditional = {}
) {
  return (props: T) => {
    const {
      isPage,
      onReset = () => {},
      onClickAction = () => {},
      customFallBackRender,
      customFallBackSuspense,
      logError = () => {},
    } = additionalProps;

    const fallBackRender =
      customFallBackRender || (isPage ? fallbackPage : fallbackComponent);

    return (
      <Suspense
        fallback={
          customFallBackSuspense !== undefined ? (
            customFallBackSuspense
          ) : (
            <div className="w-full h-screen flex justify-center items-center">
              <Spinner />
            </div>
          )
        }
      >
        <ErrorBoundary
          fallbackRender={(x: any) =>
            fallBackRender({
              ...x,
              onClickAction,
              onClearCache,
            })
          }
          onReset={onReset}
          onError={(x: any) => {
            console.info('root error:', x);
            if (logError) {
              logError(x);
            }
          }}
        >
          <Component {...(props as T)} />
        </ErrorBoundary>
      </Suspense>
    );
  };
}
