import {
  AES,
  enc,
  MD5,
  mode,
  pad,
  Rabbit,
  RC4,
  RC4Drop,
  SHA256,
} from 'crypto-js';

import { EncAlgorithm, Encryptation } from './types';

const algorithms = {
  AES,
  Rabbit,
  RC4,
  RC4Drop,
};

export function getEncryptation(
  encAlgorithm: EncAlgorithm,
  secretKey: string
): Encryptation {
  return {
    encrypt: (value: string): string => {
      return algorithms[encAlgorithm].encrypt(value, secretKey).toString();
    },
    decrypt: (value: string): string => {
      return algorithms[encAlgorithm]
        .decrypt(value, secretKey)
        .toString(enc.Utf8);
    },
  };
}

export function getEncryptationAES(secretKey: string, iv: string) {
  return {
    encrypt: (value: string): string => {
      const encrypted = AES.encrypt(value, enc.Utf8.parse(secretKey), {
        iv: enc.Utf8.parse(iv),
        mode: mode.CBC,
        padding: pad.Pkcs7,
      });
      return encrypted.toString();
    },
    decrypt: (value: string): string => {
      const decrypted = AES.decrypt(value, enc.Utf8.parse(secretKey), {
        iv: enc.Utf8.parse(iv),
        mode: mode.CBC,
        padding: pad.Pkcs7,
      });
      return decrypted.toString(enc.Utf8);
    },
  };
}

export function hashSHA256(value: string, secret: string): string {
  const hashedValue = SHA256(value, {
    secret,
  });

  return hashedValue.toString();
}

export function hashMD5(value: string, secret: string): string {
  const hashedValue = MD5(value, {
    secret,
  });

  return hashedValue.toString();
}
