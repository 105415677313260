import { useQuery } from 'react-query';
import XMLViewer from 'react-xml-viewer';
import { getXmlContent } from '../../api';

export default function Sitemap2() {
  const { isLoading, data: xmlContent } = useQuery(
    'ge-xml-content',
    () => getXmlContent(),
    {
      retry: false,
    }
  );

  const defaultXmlContent = `
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      <url>
        <loc>https://www.mobitech.id</loc>
        <loc>https://www.mobitech.id/</loc>
        <lastmod>${new Date().toISOString()}</lastmod>
        <priority>1.00</priority>
      </url>
      <url>
        <loc>https://www.mobitech.id/id</loc>
        <lastmod>${new Date().toISOString()}</lastmod>
        <priority>0.96</priority>
      </url>
      <url>
        <loc>https://www.mobitech.id/en</loc>
        <lastmod>${new Date().toISOString()}</lastmod>
        <priority>0.90</priority>
      </url>
      <url>
        <loc>https://www.mobitech.id/id/article</loc>
        <lastmod>${new Date().toISOString()}</lastmod>
        <priority>0.82</priority>
      </url>
      <url>
        <loc>https://www.mobitech.id/en/article</loc>
        <lastmod>${new Date().toISOString()}</lastmod>
        <priority>0.75</priority>
      </url>
      <url>
        <loc>https://www.mobitech.id/id/demo</loc>
        <lastmod>${new Date().toISOString()}</lastmod>
        <priority>0.68</priority>
      </url>
      <url>
        <loc>https://www.mobitech.id/en/demo</loc>
        <lastmod>${new Date().toISOString()}</lastmod>
        <priority>0.60</priority>
      </url>
    </urlset>
  `;

  const customTheme = {
    attributeKeyColor: '#9BBBDC',
    attributeValueColor: '#F29766',
    textColor: '#FFF',
    tagColor: '#5DB0D7',
    separatorColor: '#5DB0D7',
  };

  return (
    <div className="p-4 w-full min-h-screen bg-[#121212] text-white">
      <XMLViewer
        xml={isLoading || !xmlContent ? defaultXmlContent : xmlContent}
        collapsible
        theme={customTheme}
      />
    </div>
  );
}
