import { withErrorBoundary } from '@navi-app/utils';
import { useStyletron } from 'baseui';
import { HeadingLarge, ParagraphMedium } from 'baseui/typography';

export type TEmptyState = {
  imgSrc?: string;
  title?: string | React.ReactNode;
  desc?: string | React.ReactNode;
  height?: string;
  customHeader?: React.ReactNode;
  customTitle?: React.ReactNode;
  customDesc?: React.ReactNode;
  hideBorder?: boolean;
};

export const IMAGE_EMPTY_STATE =
  'https://s3.ap-southeast-1.amazonaws.com/waresix.com/assets/illustration-v3/data-belum-tersedia.svg';

const EmptyState = ({
  imgSrc,
  title,
  desc,
  height,
  customHeader,
  customTitle,
  customDesc,
  hideBorder,
}: TEmptyState) => {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        background: '#E8E8E8',
        height: height || '500px',
        borderRadius: '8px',
        display: customHeader ? 'block' : 'flex',
        border: !hideBorder ? '1px solid #E8E8E8' : undefined,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
      })}
    >
      {customHeader && (
        <div
          className={css({
            padding: '16px',
          })}
        >
          {customHeader}
        </div>
      )}
      <div
        className="flex flex-col justify-center items-center"
        style={{
          height: height ? height : 'auto',
        }}
      >
        <img
          src={imgSrc || IMAGE_EMPTY_STATE}
          alt="data-empty"
          width={312}
          height={188}
        />
        {customTitle ? (
          customTitle
        ) : (
          <HeadingLarge className="mt-6">
            {title || 'Belum Ada Data'}
          </HeadingLarge>
        )}
        {customDesc ? (
          customDesc
        ) : (
          <ParagraphMedium className="mt-3 text-center w-[380px] font-normal !text-[#5E5E5E]">
            {desc || ''}
          </ParagraphMedium>
        )}
      </div>
    </div>
  );
};
export default withErrorBoundary(EmptyState);
