import Cookies from 'js-cookie';
import { encrypted } from './encrypt-storage';

export const getStorage = (key: string, isDevelopmentMode?: boolean) => {
  if (isDevelopmentMode) {
    const profile: string | unknown = localStorage.getItem(key);
    return JSON.parse(`${profile || '{}'}`);
  }
  return encrypted.getItem(key);
};

export const getCookieStorage = (key: string, isDevelopmentMode?: boolean) => {
  const cookie = Cookies.get(key);
  if (cookie === undefined) {
    return undefined;
  }
  if (isDevelopmentMode) {
    const profile: string | unknown = Cookies.get(key);
    return JSON.parse(`${profile || '{}'}`);
  }
  const decryptedVal = encrypted.decryptValue(Cookies.get(key) as string);
  return JSON.parse(decryptedVal || '{}');
};

export const setCookieStorage = (
  key: string,
  value = {},
  domainApp: string,
  isDevelopmentMode?: boolean
) => {
  if (isDevelopmentMode) {
    Cookies.set(key, JSON.stringify(value), { domain: domainApp });
  } else {
    const encryptedVal = encrypted.encryptValue(JSON.stringify(value));
    Cookies.set(key, encryptedVal, { domain: domainApp });
  }
};

export const deleteAllCookies = () => {
  const cookies = Cookies.get();
  for (const cookie in cookies) {
    Cookies.remove(cookie);
  }
};
