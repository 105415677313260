import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { cn, useWindowSize } from '@navi-app/utils';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Modal, ModalBody, ModalHeader, ROLE, SIZE } from 'baseui/modal';
import {
  HeadingMedium,
  HeadingSmall,
  LabelLarge,
  ParagraphMedium,
  ParagraphSmall,
} from 'baseui/typography';
import { PASSWORD_VALIDATIONS } from '../constants/validation';
import ValidationMessage from './components/validation-message';
import useChangePassword from './hooks/useChangePassword';

type TPropsModalChangePassword = {
  apiUrl: string;
};

export default function ModalChangePassword({
  apiUrl,
}: TPropsModalChangePassword) {
  const {
    errors,
    handleChange,
    handleShowPassword,
    isOpen,
    onSubmit,
    showPassword,
    touched,
    values,
    closeModal,
    passwordValid,
    isLoading,
  } = useChangePassword(apiUrl);

  const { isPhoneSize } = useWindowSize();

  return (
    <Modal
      onClose={closeModal}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={isPhoneSize ? SIZE.full : SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Close: {
          style: ({ $theme }) => ({
            marginRight: '8px',
            marginTop: '16px',
            display: isPhoneSize ? 'none' : 'block',
          }),
        },
        Dialog: {
          style: ({ $theme }) => ({
            marginTop: isPhoneSize ? '92px' : '16px',
            marginRight: isPhoneSize ? '-56px' : '16px',
            marginLeft: isPhoneSize ? '-56px' : '16px',
            width: isPhoneSize ? '80%' : 'auto',
          }),
        },
      }}
    >
      <ModalHeader>
        <div
          className={cn(
            'flex items-center',
            isPhoneSize &&
              'pb-[14px] border-b-[1px] border-solid border-[#E8E8E8]'
          )}
        >
          {isPhoneSize && (
            <div
              className="flex items-center cursor-pointer"
              onClick={closeModal}
            >
              <XMarkIcon className="mr-4 w-6 h-6" />
            </div>
          )}
          {isPhoneSize ? (
            <HeadingSmall className="!-mt-1">Ubah Kata Sandi</HeadingSmall>
          ) : (
            <HeadingMedium className="!-mt-2">Ubah Kata Sandi</HeadingMedium>
          )}
        </div>
      </ModalHeader>
      <ModalBody>
        <ParagraphMedium className="!mt-4 lg:!mt-10">
          Masukkan kata sandi baru di bawah untuk mengubah Kata Sandi Anda.
        </ParagraphMedium>
        <form className="mt-6" onSubmit={onSubmit}>
          <FormControl
            label={<LabelLarge>Kata Sandi Saat Ini</LabelLarge>}
            error={
              touched?.currentPassword && errors?.currentPassword ? (
                <div className="flex items-center">
                  <InformationCircleIcon className="w-[14px] h-[14px] mr-1" />
                  <span>{errors?.currentPassword}</span>
                </div>
              ) : null
            }
          >
            <Input
              id="currentPassword"
              name="currentPassword"
              value={values?.currentPassword}
              onChange={handleChange}
              type={showPassword.isShowPasswordFirstInput ? 'text' : 'password'}
              placeholder="Masukkan kata sandi"
              overrides={{
                MaskToggleButton: {
                  style: ({ $theme }) => ({
                    display: 'none',
                  }),
                },
              }}
              endEnhancer={() =>
                !showPassword.isShowPasswordFirstInput ? (
                  <EyeSlashIcon
                    className="cursor-pointer h-5 w-5"
                    onClick={() =>
                      handleShowPassword('isShowPasswordFirstInput')
                    }
                  />
                ) : (
                  <EyeIcon
                    className="cursor-pointer h-5 w-5"
                    onClick={() =>
                      handleShowPassword('isShowPasswordFirstInput')
                    }
                  />
                )
              }
            />
          </FormControl>
          <FormControl
            label={<LabelLarge>Kata Sandi Baru</LabelLarge>}
            error={
              touched?.newPassword && errors?.newPassword ? (
                <div className="flex items-center">
                  <InformationCircleIcon className="w-[14px] h-[14px] mr-1" />
                  <span>{errors?.newPassword}</span>
                </div>
              ) : null
            }
          >
            <Input
              id="newPassword"
              name="newPassword"
              value={values?.newPassword}
              onChange={handleChange}
              type={
                showPassword.isShowPasswordSecondInput ? 'text' : 'password'
              }
              placeholder={'Masukkan kata sandi'}
              overrides={{
                MaskToggleButton: {
                  style: ({ $theme }) => ({
                    display: 'none',
                  }),
                },
              }}
              endEnhancer={() =>
                !showPassword.isShowPasswordSecondInput ? (
                  <EyeSlashIcon
                    className="cursor-pointer h-5 w-5"
                    onClick={() =>
                      handleShowPassword('isShowPasswordSecondInput')
                    }
                  />
                ) : (
                  <EyeIcon
                    className="cursor-pointer h-5 w-5"
                    onClick={() =>
                      handleShowPassword('isShowPasswordSecondInput')
                    }
                  />
                )
              }
            />
          </FormControl>
          <FormControl
            label={<LabelLarge>Ulangi Kata Sandi Baru</LabelLarge>}
            error={
              touched?.retypeNewPassword && errors?.retypeNewPassword ? (
                <div className="flex items-center">
                  <InformationCircleIcon className="w-[14px] h-[14px] mr-1" />
                  <span>{errors?.retypeNewPassword}</span>
                </div>
              ) : null
            }
          >
            <Input
              id="retypeNewPassword"
              name="retypeNewPassword"
              value={values?.retypeNewPassword}
              onChange={handleChange}
              type={showPassword.isShowPasswordLastInput ? 'text' : 'password'}
              placeholder={'Masukkan kata sandi'}
              overrides={{
                MaskToggleButton: {
                  style: ({ $theme }) => ({
                    display: 'none',
                  }),
                },
              }}
              endEnhancer={() =>
                !showPassword.isShowPasswordLastInput ? (
                  <EyeSlashIcon
                    className="cursor-pointer h-5 w-5"
                    onClick={() =>
                      handleShowPassword('isShowPasswordLastInput')
                    }
                  />
                ) : (
                  <EyeIcon
                    className="cursor-pointer h-5 w-5"
                    onClick={() =>
                      handleShowPassword('isShowPasswordLastInput')
                    }
                  />
                )
              }
            />
          </FormControl>
          {touched?.password && errors?.password && (
            <ParagraphSmall className="!mt-4" color="#DE1135">
              Kata sandi Anda harus mengandung:
            </ParagraphSmall>
          )}
          <div className="mt-4 lg:flex lg:flex-row lg:flex-wrap gap-y-2 grid grid-cols-2">
            {PASSWORD_VALIDATIONS.map((validation, index) => {
              const isValid =
                (validation.key === 'minimumChar' &&
                  passwordValid.isLengthValid) ||
                (validation.key === 'minimumNumber' &&
                  passwordValid.hasNumber) ||
                (validation.key === 'minimumCapitalChar' &&
                  passwordValid.hasUpperCase) ||
                (validation.key === 'minimumSymbol' && passwordValid.hasSymbol);

              const isEligibleShowStateError =
                touched?.newPassword &&
                errors?.newPassword &&
                values?.newPassword !== '';

              if (values?.newPassword === '') {
                return (
                  <ValidationMessage
                    key={validation.key}
                    label={validation.label}
                    type="default"
                  />
                );
              }
              return (
                <ValidationMessage
                  key={validation.key}
                  label={validation.label}
                  type={
                    isValid
                      ? 'positive'
                      : isEligibleShowStateError
                      ? 'negative'
                      : 'default'
                  }
                />
              );
            })}
          </div>
          <Button
            className="!w-full !text-base lg:!text-lg !mt-6"
            type="submit"
            isLoading={isLoading}
          >
            Ubah Kata Sandi
          </Button>
        </form>
      </ModalBody>
    </Modal>
  );
}
