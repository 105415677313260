import { withErrorBoundary } from '@navi-app/utils';
import { Button, SIZE } from 'baseui/button';
import { Check, Delete } from 'baseui/icon';
import {
  HeadingSmall,
  HeadingXSmall,
  LabelXSmall,
  ParagraphSmall,
  ParagraphXSmall,
} from 'baseui/typography';
import { TFunction } from 'i18next';
import { NavLink } from 'react-router-dom';
import config from '../../../commons/constants/config';

type TPropsPlanItem = {
  t: TFunction;
  type: string | 'free' | 'basic' | 'enterprise';
  subscriptions: Array<{
    isInclude: boolean;
    label: string;
  }>;
  whatsappLink: string;
  price: number;
};

function PlanItem({
  subscriptions,
  t,
  type,
  whatsappLink,
  price,
}: TPropsPlanItem) {
  return (
    <div className="w-[260px] hover:shadow-custom-light flex flex-col items-center">
      <div className="flex flex-col items-center gap-4 mb-[18px]">
        <div className="h-[60px] py-3 px-4 flex items-center justify-center">
          <HeadingSmall color={'#276EF1'}>{t(type)}</HeadingSmall>
        </div>
        {type === 'enterprise' ? (
          <div className="h-[96px] py-3 px-4 flex flex-col items-center">
            <HeadingXSmall color={'#A6A6A6'}>{t('talk_to_us')}</HeadingXSmall>
          </div>
        ) : (
          <div className="h-[96px] py-3 px-4 flex flex-col items-center">
            <div className="flex items-end gap-2">
              {type === 'free' ? (
                <HeadingSmall>Rp0</HeadingSmall>
              ) : (
                <HeadingSmall>
                  Rp<span>{price?.toLocaleString('id-ID')}.000</span>
                </HeadingSmall>
              )}
              <LabelXSmall color="#A6A6A6" className="mb-1">
                /{t('month')}
              </LabelXSmall>
            </div>
            {type === 'basic' && (
              <ParagraphXSmall
                color="#5E5E5E"
                className="!text-[10px] text-center mt-2"
              >
                {t('exclude_ppn')} <br />
                {t('price_can_change')}
              </ParagraphXSmall>
            )}
          </div>
        )}
        {type === 'free' && (
          <NavLink to={`${config.AUTH_URL}/register`}>
            <Button size={SIZE.compact}>{t('register')}</Button>
          </NavLink>
        )}
        {type !== 'free' && (
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <Button size={SIZE.compact}>{t('contact_us')}</Button>
          </a>
        )}
      </div>
      {subscriptions.map((indicator, index) => (
        <div
          key={index}
          className={
            'border-b border-solid border-[#E6E9EB] flex items-center justify-center h-[52px] p-4 w-full'
          }
        >
          {indicator.label ? (
            <ParagraphSmall>{indicator.label}</ParagraphSmall>
          ) : indicator.isInclude ? (
            <Check size={24} color="#3AA76D" />
          ) : (
            <Delete size={24} color="#EB7567" />
          )}
        </div>
      ))}
    </div>
  );
}

export default withErrorBoundary(PlanItem);
