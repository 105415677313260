import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { checkSubscriptionStatus } from '../api/authentication';
import { getCookieStorage, setCookieStorage } from '../storage';
import useModalStore from '../stores/use-modal.store';
import useIndexedDB from './use-indexed-db';

type TPropsUseUserAccess = {
  config: {
    isDevelopmentEnvironment: boolean;
    domainApp?: string;
    apiUrl: string;
  };
};

type TModule = 'orders' | 'planning' | 'vehicle' | 'user';

export default function useUserAccess({ config }: TPropsUseUserAccess) {
  const { openModal } = useModalStore();

  const userSubscriptionStatus = !window
    ? null
    : getCookieStorage(
        'userSubscriptionStatus',
        config.isDevelopmentEnvironment
      );
  const { getStoreData } = useIndexedDB({
    isDevelopmentEnv: config?.isDevelopmentEnvironment as boolean,
    storeName: '@user-data-store',
  });
  const userAccessData = getStoreData('userAccess');
  const userProfileData = getStoreData('userProfile');

  const userAccess = !window ? null : userAccessData;

  const userProfile = !window ? null : userProfileData;
  // For user vendor // Vendor-Admin
  const isUserVendor = useMemo(() => {
    return userProfile?.roleName === 'Vendor-Admin';
  }, [userProfile]);

  const pathName = useMemo(() => {
    if (window) {
      return window?.location?.pathname;
    }
  }, [window]);

  const getAccess = (module?: TModule) => {
    if (!isUserVendor && typeof userSubscriptionStatus !== 'undefined') {
      switch (module) {
        case 'orders':
          return userSubscriptionStatus?.orderManagement?.isHasAccess;
        case 'planning':
          return userSubscriptionStatus?.orderPlanning?.isHasAccess;
        case 'vehicle':
          return userSubscriptionStatus?.vehicleManagement?.isHasAccess;
        case 'user':
          return userSubscriptionStatus?.userManagement?.isHasAccess;
        default:
          return true;
      }
    }
    return true;
  };

  const action = (dept: VoidFunction, module?: TModule) => {
    const isHasAccess = getAccess(module);

    const errorMessage = () => {
      const generalMessage = {
        title: 'modal_subscription_finished.title',
        infoTitle: 'modal_subscription_finished.info_title',
        infoMessage: 'modal_subscription_finished.info_message',
        description: 'modal_subscription_finished.description',
      };

      switch (module) {
        case 'orders':
        case 'planning':
          if (
            userSubscriptionStatus?.orderManagement?.status ===
              'ERR_QUOTA_LIMIT_UNAUTHORIZED_ACCESS' ||
            userSubscriptionStatus?.orderPlanning?.status ===
              'ERR_QUOTA_LIMIT_UNAUTHORIZED_ACCESS'
          ) {
            return {
              title: 'modal_subscription_finished.order.title',
              infoTitle: 'modal_subscription_finished.order.info_title',
              infoMessage: 'modal_subscription_finished.info_message',
              description: 'modal_subscription_finished.description',
            };
          }
          return generalMessage;
        case 'user':
          if (
            userSubscriptionStatus?.userManagement?.status ===
            'ERR_QUOTA_LIMIT_UNAUTHORIZED_ACCESS'
          ) {
            return {
              title: 'modal_subscription_finished.user_title',
              infoTitle: 'modal_subscription_finished.user_info_title',
              infoMessage: 'modal_subscription_finished.info_message',
              description: 'modal_subscription_finished.user_description',
            };
          }
          return generalMessage;
        case 'vehicle':
          if (
            userSubscriptionStatus?.vehicleManagement?.status ===
            'ERR_QUOTA_LIMIT_UNAUTHORIZED_ACCESS'
          ) {
            return {
              title: 'modal_subscription_finished.fleet.title_quota_used',
              infoTitle:
                'modal_subscription_finished.fleet.info_title_quota_used',
              infoMessage: 'modal_subscription_finished.fleet.info_message',
              description: 'modal_subscription_finished.fleet.description',
            };
          }
          return generalMessage;
        default:
          return generalMessage;
      }
    };

    if (isHasAccess) {
      dept();
    } else {
      openModal('modalSubscriptionFinished', {
        userSubscriptionStatus,
        module,
        errorMessage: errorMessage(),
      });
    }
  };

  // Check Subscription All Status
  const { refetch: refetchSubs } = useQuery(
    'check-subscription-status',
    () => checkSubscriptionStatus({ apiUrl: config.apiUrl }),
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        setCookieStorage(
          'userSubscriptionStatus',
          {
            orderManagement: {
              status: data?.orderManagement,
              isHasAccess:
                data?.orderManagement !==
                  'ERR_INELIGIBLE_UNAUTHORIZED_ACCESS' &&
                data?.orderManagement !== 'ERR_QUOTA_LIMIT_UNAUTHORIZED_ACCESS',
            },
            orderPlanning: {
              status: data?.orderPlanning,
              isHasAccess:
                data?.orderPlanning !== 'ERR_INELIGIBLE_UNAUTHORIZED_ACCESS' &&
                data?.orderPlanning !== 'ERR_QUOTA_LIMIT_UNAUTHORIZED_ACCESS',
            },
            userManagement: {
              status: data?.user,
              isHasAccess:
                data?.user !== 'ERR_INELIGIBLE_UNAUTHORIZED_ACCESS' &&
                data?.user !== 'ERR_QUOTA_LIMIT_UNAUTHORIZED_ACCESS',
            },
            vehicleManagement: {
              status: data?.truck,
              isHasAccess:
                data?.truck !== 'ERR_INELIGIBLE_UNAUTHORIZED_ACCESS' &&
                data?.truck !== 'ERR_QUOTA_LIMIT_UNAUTHORIZED_ACCESS',
            },
          },
          config.domainApp as string,
          config.isDevelopmentEnvironment
        );
      },
    }
  );

  return {
    userProfile,
    action,
    pathName,
    userSubscriptionStatus,
    refetchSubs,
    getAccess,
    userAccess,
    isUserVendor,
  };
}
