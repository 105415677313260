import { StateStorage } from 'zustand/middleware';
import EncryptStorage from './encrypt-storage/index';

export const SECRET_KEY = 'navi-secret-key';

// get stoarge encrypt
export const encrypted = new EncryptStorage(SECRET_KEY);

// Custom storage for support encrypt in zustand
export const encryptStorage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await encrypted.getItem(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await encrypted.setItem(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await encrypted.removeItem(name);
  },
};
