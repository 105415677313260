/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  cn,
  isEmpty,
  isValidUrl,
  toPascalCaseWithSpaces,
  useIndexedDB,
  useModalStore,
  useUserAccess,
  useWindowSize,
  withErrorBoundary,
} from '@navi-app/utils';
import { NavItem } from 'baseui/app-nav-bar';
import { TFunction } from 'i18next';
import { memo, ReactNode, useEffect, useMemo, useState } from 'react';
import ModalChangePassword from '../modal-change-password';
import ModalSubscriptionFinished from '../modal-subscription-finished';
import ModalTutorialVideo from '../modal-tutorial-video';
import ModalUpdateVersion from '../modal-update-version';
import './../styles/layout.scss';
import Footer, { TFooter } from './footer';
import Header, { THeader } from './header';
import Navbar, { TNavbar } from './navbar';

type TLayoutBase = {
  customHeader?: ReactNode;
  children: ReactNode;
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  customClass?: string;
  rootClass?: string;
  showFooter?: boolean;
  useDefaultLayout?: boolean;
};

type TLayout<
  headerType = 'menu' | 'detail',
  T = headerType extends 'menu' ? TNavbar : THeader
> = {
  headerType: headerType;
  headerProps?: T;
  footerProps?: TFooter;
  config?: {
    mobiUrl?: string;
    optimaUrl?: string;
    domainApp?: string;
    apiUrl?: string;
    isDevelopmentEnv?: boolean;
    prefixPath?: string;
    tutorialUrl?: string;
  };
  customRightContent?: ReactNode;
  t?: TFunction;
} & TLayoutBase;

function Layout({
  headerType = 'menu',
  headerProps,
  footerProps,
  showFooter = true,
  customHeader,
  title,
  subTitle,
  children,
  customClass = '',
  rootClass = '',
  config,
  useDefaultLayout = true,
  customRightContent,
  t,
}: TLayout) {
  const header: any = headerProps;
  const actionHeader = header?.actionHeader;
  const { isUserVendor } = useUserAccess({
    config: {
      apiUrl: config?.apiUrl || '',
      isDevelopmentEnvironment: config?.isDevelopmentEnv || false,
      domainApp: config?.domainApp || '',
    },
  });

  const [mainItems, setMainItems] = useState<NavItem[]>([]);
  const [openSideBarMobile, setOpenSideBarMobile] = useState(false);

  const { isPhoneSize } = useWindowSize();
  const { modalName } = useModalStore();

  const { getStoreData } = useIndexedDB({
    isDevelopmentEnv: config?.isDevelopmentEnv as boolean,
    storeName: '@user-data-store',
  });
  const menuAccessData = getStoreData('menuAccess', []);
  const userProfile = getStoreData('userProfile');

  const menuAccess = useMemo(() => {
    const access = menuAccessData;
    if (access) {
      const filteredMenu = access?.filter(
        (el: any) => el.menuType !== 'hidden'
      );
      return filteredMenu;
    }
    return null;
  }, [config?.isDevelopmentEnv, menuAccessData]);

  const isValidImageUrl = isValidUrl(userProfile?.organization?.logo);

  const logoUrl = useMemo(() => {
    if (isUserVendor) {
      return isValidImageUrl ? userProfile?.organization?.logo || '' : '';
    } else {
      if (isValidImageUrl) {
        return userProfile?.organization?.logo;
      }
      return 'https://assets.mobitech.id/images/mobitech-logo.webp';
    }
  }, [isValidImageUrl, userProfile?.organization?.logo, isUserVendor]);

  const getActiveMenu = (href: string | string[], label?: string) => {
    const pathName = window?.location?.pathname || '';
    const paths = pathName.split('/').filter((path) => path !== '');
    const defaultPath =
      paths.length === 1 ? '/' : `/${paths[paths.length - 1]}`;

    if (
      label &&
      label === 'dashboard' &&
      (defaultPath === '/' || defaultPath === '/fleet-monitoring')
    )
      return true;

    if (typeof href !== 'string' && defaultPath !== '/') {
      return href.join().includes(defaultPath);
    }
    return defaultPath === href;
  };

  useEffect(() => {
    if (!menuAccess) {
      return;
    }

    const menus: any = [];
    menuAccess?.forEach((menu: any) => {
      const href =
        menu?.dropDown && menu?.dropDown?.length > 0
          ? menu?.dropDown?.map((submenu: { href: string }) => submenu?.href)
          : menu?.href;

      const isActiveMenu = getActiveMenu(href, menu.label);
      const subMenus = menu?.dropDown || menu?.dropdown || [];
      menus.push({
        label: toPascalCaseWithSpaces(menu?.label),
        children: subMenus.map((child: any) => {
          const childUrl = child?.href;
          const isActiveSubmenu = getActiveMenu(childUrl);
          return {
            label: (
              <span
                className={cn(
                  'sub-menu-item',
                  isActiveSubmenu
                    ? 'text-[#03222F] border-b border-solid border-[#03222F]'
                    : 'text-[#6B6B6B]'
                )}
              >
                {toPascalCaseWithSpaces(child?.label)}
              </span>
            ),
            info: {
              url:
                child?.app === 'optima'
                  ? `${config?.optimaUrl || ''}${childUrl}`
                  : `${config?.mobiUrl || ''}${childUrl}`,
            },
            active: isActiveSubmenu,
          };
        }),
        info: {
          url:
            menu?.app === 'optima'
              ? `${config?.optimaUrl || ''}${menu?.href}`
              : `${config?.mobiUrl || ''}${menu?.href}`,
        },
        active: isActiveMenu,
      });
    });

    if (actionHeader && !isPhoneSize && !isUserVendor) {
      menus.push({
        ...actionHeader,
        info: { ...actionHeader?.info, type: 'button' },
      });
    }
    // Append customRightContent on side bar in mobile view
    if (customRightContent && isPhoneSize) {
      menus.push({
        label: '',
        info: {
          url: '',
          className: '',
          type: 'check-delivery-price',
        },
      });
    }

    if (!isEmpty(menuAccess)) {
      setMainItems([...menus]);
    }
  }, [menuAccess, actionHeader, isPhoneSize]);

  return (
    <div className={rootClass}>
      {customHeader ||
        (headerType === 'menu' ? (
          mainItems.length > 0 ? (
            <Navbar
              {...headerProps}
              mainItems={mainItems}
              setMainItems={setMainItems}
              config={config}
              logoUrl={logoUrl}
              user={{
                name: userProfile?.name || '',
                email: userProfile?.email || '',
                profilePicture: userProfile?.profilePic || '',
              }}
              customRightContent={customRightContent}
              openSideBar={openSideBarMobile}
              setOpenSideBar={setOpenSideBarMobile}
            />
          ) : null
        ) : (
          <Header {...headerProps} title={title} subTitle={subTitle} />
        ))}
      {!openSideBarMobile && (
        <div
          className={cn(
            'h-auto custom-scroll-select',
            useDefaultLayout ? 'py-4 px-4 sm:py-6 sm:px-10' : '',
            footerProps?.customMarginBottom && showFooter
              ? `mb-[${footerProps.customMarginBottom}]`
              : '',
            !footerProps?.customMarginBottom && showFooter ? 'mb-[80px]' : '',
            customClass
          )}
        >
          {children}
        </div>
      )}
      {footerProps && showFooter && <Footer {...footerProps} />}
      {modalName === 'ModalChangePassword' && (
        <ModalChangePassword apiUrl={config?.apiUrl as string} />
      )}

      {/* Modal Subscription Finished*/}
      {modalName === 'modalSubscriptionFinished' && (
        <ModalSubscriptionFinished />
      )}

      {modalName === 'modalTutorialVideo' && (
        <ModalTutorialVideo tutorialUrl={config?.tutorialUrl} />
      )}

      {modalName === 'modal-update-version' && <ModalUpdateVersion />}
    </div>
  );
}

export default withErrorBoundary(memo(Layout));
