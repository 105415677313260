import axios, { AxiosHeaderValue, RawAxiosRequestHeaders } from 'axios';
import { setupInterceptorsTo } from './interceptor';

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

const refreshInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

setupInterceptorsTo(instance);

type initFetchType = {
  baseURL: string;
  timeout: number;
  headers?: RawAxiosRequestHeaders & { [key: string]: AxiosHeaderValue };
};

type TAdditionalConfig = {
  domainApp: string;
  authUrl: string;
};

export const initFetch = (
  config: initFetchType,
  additionalConfig: TAdditionalConfig
) => {
  instance.defaults.baseURL = config.baseURL;
  instance.defaults.timeout = config.timeout;
  instance.defaults.headers = {
    ...instance.defaults.headers,
    ...config.headers,
  };
  instance.defaults.data = {
    'domain-app': additionalConfig.domainApp,
    'auth-url': additionalConfig.authUrl,
  };
};
export const fetchApi = instance;
export const refreshApi = refreshInstance;
