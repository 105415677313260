import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getListArticle } from '../../../api';

export default function useListArticle() {
  const LIMIT = 3;

  const [pagination, setPagination] = useState({
    limit: LIMIT,
    offset: 0,
    totalItems: 0,
    currentPage: 1,
    totalPages: 0,
  });
  const [isError, setIsError] = useState(false);

  const {
    data: dataArticle,
    isLoading: isLoadingArticle,
    refetch,
  } = useQuery(
    [
      'get-list-article',
      { limit: pagination.limit, offset: pagination.offset },
    ],
    () =>
      getListArticle({
        limit:
          pagination.currentPage === 1
            ? pagination.limit + 1
            : pagination.limit,
        offset:
          pagination.currentPage === 1
            ? pagination.offset
            : pagination.offset + 1,
        status: 'published',
      }),
    {
      retry: false,
      onSuccess(data) {
        setPagination((prevState) => ({
          ...prevState,
          totalItems: data?.pagination?.total,
          totalPages: data?.pagination?.totalPage,
        }));
        setIsError(false);
      },
      onError: () => {
        setIsError(true);
      },
    }
  );

  const { data: dataArticleHighlight, isLoading: isLoadingArticleHighlight } =
    useQuery(
      ['get-list-article-hightlight'],
      () => getListArticle({ limit: 1, offset: 0, status: 'published' }),
      {
        retry: false,
      }
    );

  useEffect(() => {
    refetch();
  }, [pagination.offset, pagination.totalPages]);

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.totalPages) {
      setPagination((prevState) => ({
        ...prevState,
        offset: prevState.offset + prevState.limit,
        currentPage: prevState.currentPage + 1,
      }));
    }
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      setPagination((prevState) => ({
        ...prevState,
        offset: prevState.offset - prevState.limit,
        currentPage: prevState.currentPage - 1,
      }));
    }
  };

  const articles = useMemo(() => {
    const isFirstPage = pagination.currentPage === 1;
    if (isFirstPage) {
      return dataArticle?.list.filter((_: null, i: number) => i !== 0) || [];
    }
    return dataArticle?.list || [];
  }, [dataArticle, pagination.currentPage]);

  const isEmptyArticle = useMemo(() => {
    return !dataArticle?.list || dataArticle?.list?.length === 0;
  }, [dataArticle]);

  const hightLightContent = useMemo(() => {
    return dataArticleHighlight?.list?.[0];
  }, [dataArticleHighlight]);

  const isCanNextPage = useMemo(() => {
    return pagination.currentPage < pagination.totalPages && !isError;
  }, [pagination.currentPage, pagination.totalPages, isError]);
  const isCanPrevPage = useMemo(() => {
    return pagination.currentPage > 1;
  }, [pagination.currentPage]);

  return {
    handleNextPage,
    handlePreviousPage,
    articles,
    isLoading: isLoadingArticle || isLoadingArticleHighlight,
    hightLightContent,
    isEmptyArticle,
    isCanNextPage,
    isCanPrevPage,
    isLoadingArticle,
  };
}
