/* eslint-disable @typescript-eslint/no-explicit-any */
import { Meta } from '@navi-app/ui';
import { withErrorBoundary } from '@navi-app/utils';
import { Image } from '@unpic/react';
import { Button } from 'baseui/button';
import { HeadingSmall, ParagraphSmall } from 'baseui/typography';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../commons/constants/config';
import { layoutConfig } from '../../commons/constants/layout';
import useCheckArticle from '../../commons/hooks/use-check-article';

const HeaderLanding = lazy(
  () => import('../../commons/components/heading-landing')
);

function Error404Page() {
  const { t } = useTranslation('error');
  const lang = window.localStorage.getItem('i18nextLng') || 'id';
  const { isHasArticle, isLoadingArticle } = useCheckArticle();

  return (
    <>
      <Meta
        key="meta-not-found"
        title={t('error:meta.title')}
        description={t('error:meta.description')}
        keywords="pengiriman, order, aplikasi logistik"
        openGraph={{
          title: t('meta.title'),
          description: t('meta.description'),
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          type: 'website',
          url: window?.location?.href || config.BRAND_URL,
        }}
        twitter={{
          card: 'summary_large_image',
          description: t('meta.description'),
          title: t('meta.title'),
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          url: window?.location?.href || config.BRAND_URL,
        }}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: '404 - Page Not Found',
          description: t('meta.description'),
          mainEntity: {
            '@type': 'WebPage',
            potentialAction: {
              '@type': 'SearchAction',
              target: `${config.BRAND_URL}/search?q={search_term_string}`,
              'query-input': 'required name=search_term_string',
            },
          },
        }}
        criticalImages={['https://assets.mobitech.id/icons/error-client.webp']}
        config={config}
      />
      <Suspense fallback={null}>
        <HeaderLanding
          config={layoutConfig}
          isHasArticle={isHasArticle}
          isLoadingArticle={isLoadingArticle}
        />
      </Suspense>
      <div className="bg-white p-6 lg:p-0 rounded-lg mx-auto mt-16 lg:w-[480px]">
        <div className="flex justify-center">
          <picture>
            <source
              srcSet="https://assets.mobitech.id/icons/error-client.webp"
              type="image/webp"
            />
            <Image
              src="https://assets.mobitech.id/icons/error-client.webp"
              alt="404-not-found"
              width={480}
              height={320}
            />
          </picture>
        </div>
        <div className="text-center">
          <HeadingSmall className="mt-6">
            {t('error:error_404.title')}
          </HeadingSmall>
          <ParagraphSmall className="mt-4 mb-6" color={'#5E5E5E'}>
            {t('error:error_404.subtitle')}
            <br />
            {t('error:error_404.desc')}
          </ParagraphSmall>
          <div className="fle justify-center">
            <a href={`/${lang}`}>
              <Button overrides={{ BaseButton: { style: { width: '239px' } } }}>
                {t('error:error_404.action')}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default withErrorBoundary(Error404Page, {
  isPage: true,
});
