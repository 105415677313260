/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';

export const initSentry = (dsn: any) => {
  Sentry.init({
    dsn: dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,

    beforeSend(event: any, hint: any) {
      // Ignore expected error getBoundingClientRect from BaseUI
      if (event?.exception) {
        const error = event?.exception?.values?.[0];
        if (
          error &&
          error?.type === 'TypeError' &&
          error?.value?.includes('getBoundingClientRect')
        ) {
          return null;
        }
      }
      return event;
    },
  });
};
