import { Image } from '@unpic/react';
import { Button } from 'baseui/button';
import { HeadingSmall, ParagraphSmall } from 'baseui/typography';
import { withErrorBoundary } from '../error-boundary';

type TChildren = React.ReactNode | string;

type TContentError = {
  title: TChildren;
  subTitle: TChildren;
  imageSrc: string;
  hasButtonBack?: boolean;
  onClick?: () => void;
  textButton?: string;
};
const ContentError = ({
  title,
  subTitle,
  imageSrc,
  hasButtonBack,
  onClick,
  textButton = 'Kembali ke Halaman Utama',
}: TContentError) => (
  <div className="flex flex-col items-center justify-center text-center text-[#03222F] py-4 px-8 z-50">
    <div className="w-[70%] mb-4">
      <Image src={imageSrc} layout="fullWidth" alt="404-not-found" />
    </div>
    <HeadingSmall className="!font-semibold">{title}</HeadingSmall>
    <ParagraphSmall className="mt-2 mb-6 !font-normal" color="#5E5E5E">
      {subTitle}
    </ParagraphSmall>
    {hasButtonBack ? (
      <Button size="compact" onClick={onClick}>
        {textButton}
      </Button>
    ) : null}
  </div>
);

export default withErrorBoundary(ContentError);
