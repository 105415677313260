/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useIndexedDBProvider } from '../indexedDB/indexed-db-provider';
import { indexedDBStorage } from '../indexedDB/indexed-db-storage';
import { isEmpty } from '../utils';

type TProps = {
  isDevelopmentEnv: boolean;
  storeName: string;
};

export default function useIndexedDB(props?: TProps) {
  const { db, dataDBUser, setDataDBUser } = useIndexedDBProvider();

  const { getAll, setItem, getItem, clearAll, setBulkItem } = indexedDBStorage({
    isDevelopmentEnv: props?.isDevelopmentEnv as boolean,
    storeName: props?.storeName as string, //  used for user auth data store
    dbConnection: db as IDBDatabase,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tempData, setTempData] = useState<any>({});

  useEffect(() => {
    const getData = async () => {
      const response = await getAll();
      if (response) {
        setTempData(response);
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (!isEmpty(tempData)) {
      setDataDBUser(tempData);
    }
  }, [tempData]);

  const getStoreData = (key: string, defaultValue?: any) => {
    return key in dataDBUser ? dataDBUser[key] : defaultValue ?? {};
  };

  return {
    isLoading,
    getStoreData,
    storeData: setItem,
    storeBulkData: setBulkItem,
    clearAll,
    getItem,
  };
}
