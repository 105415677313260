import { useWindowSize } from '@navi-app/utils';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCheckArticle from '../../../commons/hooks/use-check-article';
import {
  integrationIndicator,
  mainIndicators,
  subscriptionIndicators,
} from '../constants/subscription';

type TEventValueTab = {
  activeKey: React.Key;
};

export default function useSubscription() {
  const { t } = useTranslation('subscription');
  const { isHasArticle, isLoadingArticle } = useCheckArticle();
  const [isSimulateDiscount, setIsSimulateDiscount] = useState(false);
  const [isShowDifferent, setIsShowDifferent] = useState(false);
  const [price, setPrice] = useState(700);
  const [activeTabKey, setActiveTabKey] = useState('0');
  const { isPhoneSize } = useWindowSize();

  useEffect(() => {
    const startPrice = isSimulateDiscount ? 700 : 500;
    const endPrice = isSimulateDiscount ? 500 : 700;
    const duration = 1000; // 1 second animation
    const stepTime = Math.abs(Math.floor(duration / (endPrice - startPrice)));

    let current = startPrice;
    const increment = endPrice > startPrice ? 1 : -1;

    const timer = setInterval(() => {
      current += increment;
      setPrice(current);
      if (current === endPrice) clearInterval(timer);
    }, stepTime);

    return () => clearInterval(timer);
  }, [isSimulateDiscount]);

  const handleChangeSimulateDiscount = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIsSimulateDiscount(event.target.checked);
  };

  const handleChangeShowDifferent = (event: ChangeEvent<HTMLInputElement>) => {
    setIsShowDifferent(event.target.checked);
  };

  const getGrettingMessage = (subscriptionType: string) => {
    const type = subscriptionType === 'basic' ? 'Basic' : 'Enterprise';
    return `https://wa.me/6281113607111?text=Halo%2C%20saya%20ingin%20bertanya%20lebih%20lanjut%20mengenai%20subscription%20plan%20${type}.`;
  };

  const subscriptions = useMemo(() => {
    if (isShowDifferent) {
      return mainIndicators.concat(integrationIndicator);
    }
    return subscriptionIndicators;
  }, [isShowDifferent]);

  const plans = useMemo(() => {
    return [
      {
        type: 'free',
        subscriptions: subscriptions.map((subscription, index) => {
          return {
            ...subscription.free,
            name: subscription.name,
          };
        }),
      },
      {
        type: 'basic',
        subscriptions: subscriptions.map((subscription, index) => {
          return {
            ...subscription.basic,
            name: subscription.name,
          };
        }),
      },
      {
        type: 'enterprise',
        subscriptions: subscriptions.map((subscription, index) => {
          return {
            ...subscription.enterprise,
            name: subscription.name,
          };
        }),
      },
    ];
  }, [subscriptions]);

  const handleChangeTab = ({ activeKey }: TEventValueTab) => {
    setActiveTabKey(activeKey as string);
  };

  return {
    isSimulateDiscount,
    isHasArticle,
    isLoadingArticle,
    isShowDifferent,
    handleChangeSimulateDiscount,
    handleChangeShowDifferent,
    getGrettingMessage,
    price,
    subscriptions,
    t,
    plans,
    isPhoneSize,
    activeTabKey,
    handleChangeTab,
  };
}
