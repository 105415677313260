import { Meta } from '@navi-app/ui';
import { useWindowSize, withErrorBoundary } from '@navi-app/utils';
import { StyledDivider } from 'baseui/divider';
import { DisplayMedium, ParagraphMedium } from 'baseui/typography';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import CallToAction from '../../commons/components/call-to-action';
import config from '../../commons/constants/config';
import { layoutConfig } from '../../commons/constants/layout';
import useLanding from '../landing/hooks/use-landing';

const HeroComponent = lazy(() => import('./components/hero-component'));
const OrderPlanningFeatures = lazy(
  () => import('./components/order-planning-features')
);
const SmartPlanningFeatures = lazy(
  () => import('./components/smart-planning-features')
);
const HeaderLanding = lazy(
  () => import('../../commons/components/heading-landing')
);
const FooterLanding = lazy(
  () => import('../../commons/components/footer-landing')
);

function OrderPlanningPage() {
  const lang = window?.localStorage?.getItem('i18nextLng') || 'id';
  const { t } = useTranslation(['common', 'landing', 'smart-planning']);

  const { isHasArticle, isLoadingArticle } = useLanding();
  const { isPhoneSize } = useWindowSize();

  const metaTitleLang = {
    id: 'Mobitech: Aplikasi Logistik Terbaik Berbasis Teknologi AI',
    en: 'Mobitech: Logistics Application',
  };
  const title =
    t('meta.title') === 'meta.title'
      ? lang === 'id'
        ? metaTitleLang.id
        : metaTitleLang.en
      : t('meta.title');

  return (
    <>
      <Meta
        key="meta-landing"
        title={title}
        description={t('meta.description')}
        keywords="pengiriman, order, aplikasi logistik"
        openGraph={{
          title: t('meta.title'),
          description: t('meta.description'),
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          type: 'website',
          url: window?.location?.href || config.BRAND_URL,
        }}
        twitter={{
          card: 'summary_large_image',
          description: t('meta.description'),
          title: t('meta.title'),
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          url: window?.location?.href || config.BRAND_URL,
        }}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'Mobitech ID',
          url: config.BRAND_URL,
          logo: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: '+62 811-1360-7111',
            contactType: 'Customer Service',
            email: 'care@mobitech.id',
          },
          sameAs: [
            'https://www.facebook.com/people/Mobitechid/61560697193870',
            'https://linkedin.com/company/mobitech-id',
          ],
          telephone: '+62 811-1360-7111',
          email: 'care@mobitech.id',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Jalan Sultan Iskandar Muda No.10',
            addressLocality: 'Jakarta Selatan',
            addressCountry: 'ID',
            addressRegion: 'DKI Jakarta',
            postalCode: '12240',
          },
        }}
        canonicalLink={config.BRAND_URL}
        criticalImages={[
          '/images/smart-planning-pinpoint.webp',
          '/images/input-order-planning-image.webp',
          '/images/generate-plan-image.webp',
          '/images/order-planning-image.webp',
        ]}
        config={config}
      />
      <Suspense>
        <HeaderLanding
          config={layoutConfig}
          isHasArticle={isHasArticle}
          isLoadingArticle={isLoadingArticle}
        />
      </Suspense>
      <HeroComponent />

      <section className="my-14 md:my-20 lg:mx-20 p-4 md:p-0">
        <DisplayMedium
          className="!text-[28px] !leading-9 lg:!text-[44px] lg:!leading-[52px] !font-semibold text-center mb-6 break-words md:px-20"
          as="h1"
        >
          {t('smart-planning:efficiency.title')}
        </DisplayMedium>
        <ParagraphMedium color="#7c7c7c" className="mb-6 text-center">
          {t('smart-planning:efficiency.subtitle')}
        </ParagraphMedium>
      </section>

      <OrderPlanningFeatures />
      <SmartPlanningFeatures />
      {isPhoneSize && <StyledDivider />}
      <CallToAction t={t} />
      <FooterLanding />
    </>
  );
}

export default withErrorBoundary(OrderPlanningPage);
