import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { getDetailArticleByPermaLink, getListArticle } from '../../../api';

export default function useArticleDetail() {
  const { permaLink } = useParams<{ permaLink?: string }>();
  const params = {
    limit: 3,
    offset: 0,
    excludeIds: permaLink || '',
    status: 'published',
  };

  const queryKey = {
    detail: 'get-detail-article',
    article: 'get-list-article-excluded',
  };

  const {
    data: detailArticle,
    isLoading: isLoadingDetailArticle,
    refetch: refetchDetail,
  } = useQuery(
    [queryKey.detail],
    () => getDetailArticleByPermaLink(permaLink || ''),
    {
      retry: false,
      enabled: false,
    }
  );

  const { data: listArticle, isLoading: isLoadingListArticle } = useQuery(
    [queryKey.article],
    () =>
      getListArticle({
        ...params,
        excludeIds: detailArticle?.id,
      }),
    {
      retry: false,
      enabled: !!detailArticle?.id,
    }
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.removeQueries();
    refetchDetail();
  }, [permaLink]);

  return {
    detailArticle,
    isLoadingDetailArticle,
    listArticle: listArticle?.list || [],
    isLoadingListArticle,
    permaLink,
  };
}
