import { useWindowSize } from '@navi-app/utils';
import { useEffect } from 'react';
import useCheckArticle from '../../../commons/hooks/use-check-article';

export default function useLanding() {
  const { isHasArticle, isLoadingArticle } = useCheckArticle();
  const { isPhoneSize } = useWindowSize();

  // handling scroll
  const scrollToProductSection = () => {
    const sectionProduct = document?.getElementById('product');
    window?.scrollBy({
      top: isPhoneSize
        ? (sectionProduct?.offsetHeight || 0) - 40
        : window?.innerHeight + (sectionProduct?.offsetHeight || 0) + 120,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (window?.location?.hash === '#product') {
      scrollToProductSection();
    }
    return () => {
      window?.scrollTo(0, 0);
    };
  }, [window?.location?.hash, isPhoneSize]);

  return {
    isHasArticle,
    isLoadingArticle,
  };
}
