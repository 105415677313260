import ReactGA from 'react-ga4';

export default function useAnalyticEvent() {
  const sendEvent = (name: string, isSendPageView?: boolean) => {
    ReactGA.event({
      category: 'User',
      action: 'click_button',
      label: `Landing Page: ${name}`,
    });

    // Case Login Page
    if (isSendPageView) {
      ReactGA.send({
        hitType: 'pageview',
        page: '/auth',
        title: `Mobitech: TMS: Login: CTA Landing Page`,
      });
    }
  };

  return {
    sendEvent,
  };
}
