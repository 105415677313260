export const mainIndicators = [
  {
    name: 'User',
    free: {
      isInclude: true,
      label: '3 Users',
    },
    basic: {
      isInclude: true,
      label: '10 Users',
    },
    enterprise: {
      isInclude: true,
      label: 'Unlimited',
    },
  },
  {
    name: 'Sales Order',
    free: {
      isInclude: false,
      label: '',
    },
    basic: {
      isInclude: false,
      label: '',
    },
    enterprise: {
      isInclude: true,
      label: 'Unlimited',
    },
  },
  {
    name: 'Maximum Delivery Order',
    free: {
      isInclude: true,
      label: '50 DO',
    },
    basic: {
      isInclude: true,
      label: '150 DO',
    },
    enterprise: {
      isInclude: true,
      label: 'Unlimited',
    },
  },
  {
    name: 'Maximum Number of Fleet',
    free: {
      isInclude: true,
      label: '5 fleets',
    },
    basic: {
      isInclude: true,
      label: '10 fleets',
    },
    enterprise: {
      isInclude: true,
      label: 'Unlimited',
    },
  },
  {
    name: 'Order Planning',
    free: {
      isInclude: false,
      label: '',
    },
    basic: {
      isInclude: false,
      label: '',
    },
    enterprise: {
      isInclude: true,
      label: '',
    },
  },
];

export const integrationIndicator = [
  {
    name: 'API Integration',
    free: {
      isInclude: false,
      label: '',
    },
    basic: {
      isInclude: false,
      label: '',
    },
    enterprise: {
      isInclude: true,
      label: '',
    },
  },
  {
    name: 'GPS Integration',
    free: {
      isInclude: false,
      label: '',
    },
    basic: {
      isInclude: true,
      label: '',
    },
    enterprise: {
      isInclude: true,
      label: '',
    },
  },
];

export const subscriptionIndicators = [
  ...mainIndicators,
  {
    name: 'Order Management',
    free: {
      isInclude: true,
      label: '',
    },
    basic: {
      isInclude: true,
      label: '',
    },
    enterprise: {
      isInclude: true,
      label: '',
    },
  },
  {
    name: 'Invoice Management',
    free: {
      isInclude: true,
      label: '',
    },
    basic: {
      isInclude: true,
      label: '',
    },
    enterprise: {
      isInclude: true,
      label: '',
    },
  },
  {
    name: 'Driver tracking via whatsapp',
    free: {
      isInclude: true,
      label: '',
    },
    basic: {
      isInclude: true,
      label: '',
    },
    enterprise: {
      isInclude: true,
      label: '',
    },
  },
  {
    name: 'Dashboard',
    free: {
      isInclude: true,
      label: '',
    },
    basic: {
      isInclude: true,
      label: '',
    },
    enterprise: {
      isInclude: true,
      label: '',
    },
  },
  {
    name: 'Fleet Monitoring',
    free: {
      isInclude: true,
      label: '',
    },
    basic: {
      isInclude: true,
      label: '',
    },
    enterprise: {
      isInclude: true,
      label: '',
    },
  },
  ...integrationIndicator,
];
