import { Meta } from '@navi-app/ui';
import { withErrorBoundary } from '@navi-app/utils';
import { StyledDivider } from 'baseui/divider';
import React, { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import CallToAction from '../../commons/components/call-to-action';
import config from '../../commons/constants/config';
import { layoutConfig } from '../../commons/constants/layout';
import SectionAdvantage from './components/section-advantage';
import useLanding from './hooks/use-landing';

const HeaderLanding = lazy(
  () => import('../../commons/components/heading-landing')
);
const FooterLanding = lazy(
  () => import('../../commons/components/footer-landing')
);

const SectionMain = lazy(() => import('./components/section-main'));
const SectionOptima = lazy(() => import('./components/section-optima'));
const SectionMobi = lazy(() => import('./components/section_mobi'));
const SectionCapability = lazy(() => import('./components/section-capability'));
const SectionTestimony = React.lazy(
  () => import('./components/section-testimony')
);

function LandingPage() {
  const { t } = useTranslation(['landing', 'common']);
  const { isHasArticle, isLoadingArticle } = useLanding();
  const lang = window?.localStorage?.getItem('i18nextLng') || 'id';

  // Handling for case translation not loaded to tracking better title page analytics
  const metaTitleLang = {
    id: 'Mobitech: Aplikasi Logistik Terbaik Berbasis Teknologi AI',
    en: 'Mobitech: Logistics Application',
  };
  const title =
    t('meta.title') === 'meta.title'
      ? lang === 'id'
        ? metaTitleLang.id
        : metaTitleLang.en
      : t('meta.title');

  return (
    <>
      <Meta
        key="meta-landing"
        title={title}
        description={t('meta.description')}
        keywords="pengiriman, order, aplikasi logistik"
        openGraph={{
          title: t('meta.title'),
          description: t('meta.description'),
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          type: 'website',
          url: window?.location?.href || config.BRAND_URL,
        }}
        twitter={{
          card: 'summary_large_image',
          description: t('meta.description'),
          title: t('meta.title'),
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          url: window?.location?.href || config.BRAND_URL,
        }}
        structuredData={{
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'Mobitech ID',
          url: config.BRAND_URL,
          logo: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: '+62 811-1360-7111',
            contactType: 'Customer Service',
            email: 'care@mobitech.id',
          },
          sameAs: [
            'https://www.facebook.com/people/Mobitechid/61560697193870',
            'https://linkedin.com/company/mobitech-id',
          ],
          telephone: '+62 811-1360-7111',
          email: 'care@mobitech.id',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Jalan Sultan Iskandar Muda No.10',
            addressLocality: 'Jakarta Selatan',
            addressCountry: 'ID',
            addressRegion: 'DKI Jakarta',
            postalCode: '12240',
          },
        }}
        canonicalLink={config.BRAND_URL}
        criticalImages={[
          '/images/background-mobi.webp',
          '/images/input-order-planning-image.webp',
          '/images/generate-plan-image.webp',
          '/images/order-planning-image.webp',
          '/images/order-creation-image.webp',
          '/images/trip-assigment-image.webp',
          '/images/realtime-driver-update-image.webp',
          '/images/dashboard-and-analytics-image.webp',
          '/images/order-management-list.webp',
          '/images/order-planning-image.webp',
        ]}
        config={config}
      />
      <Suspense>
        <div className="container">
          <HeaderLanding
            config={layoutConfig}
            isHasArticle={isHasArticle}
            isLoadingArticle={isLoadingArticle}
          />
          <main className="!scroll-smooth">
            <SectionMain />
            <SectionAdvantage />
            <SectionOptima />
            <SectionMobi />
            <SectionCapability />
            <SectionTestimony />
            <StyledDivider />
            <CallToAction t={t} />
          </main>
          <FooterLanding />
        </div>
      </Suspense>
    </>
  );
}

export default withErrorBoundary(LandingPage, {
  isPage: true,
});
