import { create } from 'zustand';

type TLocationItem = {
  id: number;
  name: string;
  prefix: string;
  longitude: number;
  latitude: number;
};

type TCreateLocType = {
  name: string;
  cityId: number | string;
  districtId: number | string;
  address: string;
  longitude: number;
  latitude: number;
  openTime?: Date;
  closeTime?: Date;
  cities: Array<TLocationItem>;
  districts: Array<TLocationItem>;
  onSetData: (value: unknown, key: string) => void;
  onReset: () => void;
};

const defaultState = {
  name: '',
  cityId: '',
  districtId: '',
  address: '',
  longitude: 0,
  latitude: 0,
  openTime: undefined,
  closeTime: undefined,
  cities: [],
  districts: [],
};

const useCreateLocationStore = create<TCreateLocType>((set) => ({
  ...defaultState,
  onSetData: (value, key) => set(() => ({ [key]: value })),
  onReset: () => set(() => ({ ...defaultState })),
}));

export default useCreateLocationStore;
