import { withErrorBoundary } from '@navi-app/utils';
import { Navigate, Route, Routes as RoutesLib } from 'react-router-dom';
import Article from '../pages/article';
import ArticleDetail from '../pages/article-detail';
import DemoPage from '../pages/demo';
import Error404Page from '../pages/error/404';
import LandingPage from '../pages/landing';
import OrderManagementPage from '../pages/order-management';
import OrderPlanningPage from '../pages/order-planning';
import Sitemap from '../pages/sitemap';
import Subscription from '../pages/subscription';

function Routes() {
  return (
    <RoutesLib>
      <Route path="/" element={<Navigate replace to="/id" />} />
      <Route path="/demo" element={<Navigate replace to="/id/demo" />} />
      <Route path="/article" element={<Navigate replace to="/id/article" />} />
      <Route
        path="/subscription"
        element={<Navigate replace to="/id/subscription" />}
      />
      <Route path="/id/subscription" element={<Subscription />} />
      <Route path="/en/subscription" element={<Subscription />} />
      <Route path="/id" element={<LandingPage />} />
      <Route path="/en" element={<LandingPage />} />
      <Route path="/id/demo" element={<DemoPage />} />
      <Route path="/en/demo" element={<DemoPage />} />
      <Route path="/sitemap.xml" element={<Sitemap />} />
      <Route path="/smart-planning" element={<OrderPlanningPage />} />
      <Route path="/order-management" element={<OrderManagementPage />} />
      <Route path="/id/article" element={<Article />} />
      <Route path="/en/article" element={<Article />} />
      <Route path="/id/article/:permaLink" element={<ArticleDetail />} />
      <Route path="/en/article/:permaLink" element={<ArticleDetail />} />
      <Route path="/404" element={<Error404Page />} />
      <Route path="*" element={<Error404Page />} />
    </RoutesLib>
  );
}

export default withErrorBoundary(Routes, {
  isPage: true,
});
