import {
  apiAuthentication,
  hasNumber,
  hasSymbol,
  hasUpperCase,
  isLengthValid,
  useForm,
  useModalStore,
} from '@navi-app/utils';
import { AxiosError } from 'axios';
import { FormEvent, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { toaster } from '../../toaster';

export default function useChangePassword(apiUrl: string) {
  const { modalName, closeModal, modalData } = useModalStore();
  const [showPassword, setShowPassword] = useState({
    isShowPasswordFirstInput: false,
    isShowPasswordSecondInput: false,
    isShowPasswordLastInput: false,
  });

  const { mutate, isLoading } = useMutation(apiAuthentication.changePassword);
  const {
    errors,
    handleChange,
    values,
    handleSubmit,
    touched,
    resetForm,
    setErrors,
  } = useForm({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      retypeNewPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('Wajib diisi'),
      newPassword: Yup.string()
        .notOneOf(
          [Yup.ref('currentPassword')],
          'Kata sandi baru tidak boleh sama dengan sebelumnya.'
        )
        .min(10, 'Kata sandi belum sesuai')
        .matches(/[A-Z]/, 'Kata sandi belum sesuai')
        .matches(/[0-9]/, 'Kata sandi belum sesuai')
        .matches(/[\W_]/, 'Kata sandi belum sesuai')
        .required('Wajib diisi'),
      retypeNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Kata sandi harus sama dengan di atas')
        .required('Wajib diisi'),
    }),
    onSubmit: (values) => {
      mutate(
        {
          apiUrl,
          payload: {
            oldPassword: values.currentPassword,
            newPassword: values.newPassword,
            reNewPassword: values.retypeNewPassword,
          },
        },
        {
          onSuccess: () => {
            closeModal();
            toaster.show('Kata sandi berhasil diubah.');
            setTimeout(() => {
              modalData?.callback();
            }, 600);
          },
          onError: (error) => {
            const errorResponse = error as AxiosError;
            const errorData = errorResponse.response?.data as {
              message: string;
              errorCode: string;
            };

            const isInValidOldPassword =
              errorData.errorCode === 'ERR_SHR_01_00008' ||
              errorData.message === 'invalid old password';
            if (isInValidOldPassword) {
              setErrors({
                currentPassword: 'Password lama tidak valid',
              });
            } else {
              const messages =
                errorData.errorCode === 'ERR_SHR_01_00007'
                  ? 'Password belum sesuai kriteria.'
                  : errorData.errorCode === 'ERR_SHR_01_00009'
                  ? 'Kata sandi baru tidak boleh sama dengan sebelumnya.'
                  : errorData?.message || 'Kata sandi gagal diubah.';
              toaster.negative(messages);
            }
          },
        }
      );
    },
  });

  const handleShowPassword = (
    key:
      | 'isShowPasswordFirstInput'
      | 'isShowPasswordSecondInput'
      | 'isShowPasswordLastInput'
  ) =>
    setShowPassword((prevState) => ({
      ...prevState,
      [`${key}`]: !prevState[`${key}`],
    }));

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit();
  };

  const isOpen = modalName === 'ModalChangePassword';

  const passwordValid = useMemo(() => {
    return {
      isLengthValid: isLengthValid(values?.newPassword, 10),
      hasUpperCase: hasUpperCase(values?.newPassword),
      hasNumber: hasNumber(values?.newPassword),
      hasSymbol: hasSymbol(values?.newPassword),
    };
  }, [values?.newPassword]);

  const handleCloseModal = () => {
    closeModal();
    resetForm();
  };

  return {
    onSubmit,
    showPassword,
    handleShowPassword,
    errors,
    handleChange,
    values,
    touched,
    isOpen,
    closeModal: handleCloseModal,
    passwordValid,
    isLoading,
  };
}
