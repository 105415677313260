export const PASSWORD_VALIDATIONS = [
  { key: 'minimumChar', label: 'Min. panjang 10 karakter' },
  { key: 'minimumNumber', label: 'Min. 1 nomor' },
  {
    key: 'minimumCapitalChar',
    label: 'Min. 1 huruf kapital',
  },
  {
    key: 'minimumSymbol',
    label: 'Min. 1 karakter khusus (contoh: ! $ / ? ( ) #)',
  },
];
