import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/16/solid';
import { ToastPropsShape, toaster as toasterBaseUI } from 'baseui/toast';
import { LabelMedium, ParagraphMedium } from 'baseui/typography';
import React from 'react';

type TChildren = React.ReactNode | string;

type TContent = {
  title: TChildren;
  subTitle: TChildren;
  icon: React.ReactNode;
  color?: string;
};

const Content = ({ title, subTitle, icon, color = '#03222F' }: TContent) => (
  <div className="flex items-center text-[#03222F]">
    <div className="w-6 mr-4 mt-1">{icon}</div>
    <div>
      <LabelMedium color={color}>{title}</LabelMedium>
      {subTitle && (
        <div className="mt-1">
          <ParagraphMedium color={color}>{subTitle}</ParagraphMedium>
        </div>
      )}
    </div>
  </div>
);

const toaster = {
  ...toasterBaseUI,
  info: (title: TChildren, subTitle?: TChildren, props?: ToastPropsShape) => {
    toasterBaseUI.info(
      <Content
        title={title}
        subTitle={subTitle}
        icon={
          <ExclamationCircleIcon className="text-[#03222F] mr-4 w-6 h-6 mt-1" />
        }
      />,
      props
    );
  },
  positive: (
    title: TChildren,
    subTitle?: TChildren,
    props?: ToastPropsShape
  ) => {
    toasterBaseUI.positive(
      <Content
        title={title}
        subTitle={subTitle}
        icon={<CheckCircleIcon className="text-[#03222F] mr-4 w-6 h-6  mt-1" />}
      />,
      props
    );
  },
  warning: (
    title: TChildren,
    subTitle?: TChildren,
    props?: ToastPropsShape
  ) => {
    toasterBaseUI.warning(
      <Content
        title={title}
        subTitle={subTitle}
        icon={
          <ExclamationTriangleIcon className="text-[#03222F] mr-4 w-6 h-6  mt-1" />
        }
      />,
      props
    );
  },
  negative: (
    title: TChildren,
    subTitle?: TChildren,
    props?: ToastPropsShape
  ) => {
    toasterBaseUI.negative(
      <Content
        title={title}
        subTitle={subTitle}
        icon={
          <ExclamationCircleIcon className="text-[#03222F] mr-4 w-6 h-6  mt-1" />
        }
      />,
      props
    );
  },
  show: (title: TChildren, subTitle?: TChildren, props?: ToastPropsShape) => {
    toasterBaseUI.show(
      <Content
        title={title}
        subTitle={subTitle}
        icon={<CheckCircleIcon className="text-[#fff] mr-4 w-5 h-5  mt-1" />}
        color="#fff"
      />,
      props
    );
  },
};

export { toaster };
