import { withErrorBoundary } from '@navi-app/utils';
import { Badge, BadgeOverrides, HIERARCHY } from 'baseui/badge';
import { ReactNode } from 'react';
import TextEllipsis from './text-ellipsis';

type TTag = {
  content: string | ReactNode;
  kind?:
    | 'primary'
    | 'accent'
    | 'accent-solid'
    | 'positive'
    | 'warning'
    | 'light-warning'
    | 'negative'
    | 'dark'
    | 'secondary'
    | 'light-secondary';
  customClass?: string;
  overrides?: BadgeOverrides;
  ellipsisLength?: number;
  hideEllipsis?: boolean;
};

const TagComponent = ({
  content,
  kind,
  customClass,
  overrides,
  ellipsisLength,
  hideEllipsis,
}: TTag) => {
  const getColor = () => {
    switch (kind) {
      case 'dark':
        // Dark: Dark Gray
        return { border: '#5E5E5E', text: '#F3F3F3', fill: '#727272' };
      case 'accent':
        // Accent: Light Blue
        return { border: '#DEE9FE', text: '#175BCC', fill: '#EFF4FE' };
      case 'accent-solid':
        // Accent Solid: Medium Blue
        return { border: '#175BCC', text: '#EFF4FE', fill: '#276EF1' };
      case 'negative':
        // Negative: Light Red
        return { border: '#FFE1DE', text: '#BB032A', fill: '#FFF0EE' };
      case 'warning':
        // Warning: Light Yellow
        return { border: '#FBE5B6', text: '#845201', fill: '#FDF2DC' };
      case 'light-warning':
        // Light Warning: Medium Yellow
        return { border: '#D79900', text: '#392300', fill: '#F6BC2F' };
      case 'positive':
        // Positive: Light Green
        return { border: '#D3EFDA', text: '#166C3B', fill: '#EAF6ED' };
      case 'secondary':
        // Secondary: Medium Purple
        return { border: '#7C3EC3', text: '#F9F1FF', fill: '#944DE7' };
      case 'light-secondary':
        // Light Secondary: Light Purple
        return { border: '#F2E3FF', text: '#7C3EC3', fill: '#F9F1FF' };
      case 'primary':
      default:
        // Primary (Default): Light Gray
        return { border: '#E8E8E8', text: '#5E5E5E', fill: '#F3F3F3' };
    }
  };
  const isWidthEllipsis =
    ellipsisLength &&
    typeof content === 'string' &&
    content?.length > ellipsisLength;
  const contentBadge = isWidthEllipsis ? (
    <div
      style={{ width: ellipsisLength * 10 }}
      className="overflow-hidden whitespace-nowrap truncate"
    >
      {content}
    </div>
  ) : (
    content
  );

  return hideEllipsis ? (
    <div className={customClass}>
      <Badge
        hierarchy={HIERARCHY.secondary}
        content={content}
        overrides={{
          ...overrides,
          Badge: {
            style: {
              padding: '4px 6px',
              border: `1px solid ${getColor().border}`,
              color: getColor().text,
              backgroundColor: getColor().fill,
              ...overrides?.Badge?.style,
            },
          },
        }}
      />
    </div>
  ) : (
    <div className={customClass}>
      <TextEllipsis
        line={1}
        propsTooltip={{ content, minLengthText: ellipsisLength }}
      >
        <Badge
          hierarchy={HIERARCHY.secondary}
          content={contentBadge}
          overrides={{
            ...overrides,
            Badge: {
              style: {
                padding: '4px 6px',
                border: `1px solid ${getColor().border}`,
                color: getColor().text,
                backgroundColor: getColor().fill,
                ...overrides?.Badge?.style,
              },
            },
          }}
        />
      </TextEllipsis>
    </div>
  );
};

export default withErrorBoundary(TagComponent);
