const {
  VITE_MOBI_URL,
  VITE_OPTIMA_URL,
  VITE_BRAND_URL,
  VITE_AUTH_URL,
  VITE_AUTH_REGISTER_URL,
  VITE_DOMAIN_APP,
  VITE_API_URL,
  VITE_ENVIRONMENT,
} = import.meta.env;

export const layoutConfig = {
  mobiUrl: VITE_MOBI_URL,
  optimaUrl: VITE_OPTIMA_URL,
  brandUrl: VITE_BRAND_URL,
  authUrl: VITE_AUTH_URL,
  authRegisterUrl: VITE_AUTH_REGISTER_URL,
  domainApp: VITE_DOMAIN_APP || '.mobitech.id',
  apiUrl: VITE_API_URL,
  isDevelopmentEnv: VITE_ENVIRONMENT === 'development',
};
