import { withErrorBoundary } from '@navi-app/utils';
import { Button, SIZE } from 'baseui/button';
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';
import { Check, Delete } from 'baseui/icon';
import { Tab, Tabs } from 'baseui/tabs-motion';
import {
  DisplaySmall,
  HeadingMedium,
  HeadingSmall,
  HeadingXSmall,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  LabelXSmall,
  ParagraphSmall,
  ParagraphXSmall,
} from 'baseui/typography';
import { lazy } from 'react';
import { NavLink } from 'react-router-dom';
import config from '../../commons/constants/config';
import { layoutConfig } from '../../commons/constants/layout';
import PlanItem from './components/plan-item';
import useSubscription from './hooks/use-subscription';

const FooterLanding = lazy(
  () => import('../../commons/components/footer-landing')
);
const HeaderLanding = lazy(
  () => import('../../commons/components/heading-landing')
);

function SubscriptionPage() {
  const {
    isHasArticle,
    isLoadingArticle,
    handleChangeShowDifferent,
    handleChangeSimulateDiscount,
    isShowDifferent,
    isSimulateDiscount,
    getGrettingMessage,
    price,
    subscriptions,
    t,
    plans,
    isPhoneSize,
    activeTabKey,
    handleChangeTab,
  } = useSubscription();

  return (
    <>
      <HeaderLanding
        config={layoutConfig}
        isHasArticle={isHasArticle}
        isLoadingArticle={isLoadingArticle}
      />
      <main className="lg:px-[120px] px-[20px] py-6 flex flex-col items-center gap-6">
        {isPhoneSize ? (
          <div className="flex flex-col gap-4 items-center">
            <HeadingMedium className="text-center">{t('title')}</HeadingMedium>
            <LabelMedium color="#A6A6A6" className="text-center">
              {t('subtitle')} <br />
              {t('second_subtitle')}
            </LabelMedium>
          </div>
        ) : (
          <div className="flex flex-col gap-4 items-center">
            <DisplaySmall>{t('title')}</DisplaySmall>
            <LabelLarge color="#A6A6A6" className="text-center">
              {t('subtitle')} <br />
              {t('second_subtitle')}
            </LabelLarge>
          </div>
        )}
        <div className="flex items-center justify-center gap-3">
          {/*  */}
          <Checkbox
            checked={isSimulateDiscount}
            checkmarkType={STYLE_TYPE.toggle_round}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  alignItems: 'center',
                  justifyContent: 'center',
                }),
              },
              Toggle: {
                style: ({ $theme }) => ({
                  outline: isSimulateDiscount
                    ? `#1E64DC solid`
                    : `#A6A6A6 solid`,
                  backgroundColor: '#fff',
                }),
              },
              ToggleTrack: {
                style: ({ $theme }) => ({
                  backgroundColor: isSimulateDiscount ? '#1E64DC' : '#A6A6A6',
                }),
              },
            }}
            onChange={handleChangeSimulateDiscount}
          >
            <div className="flex items-center lg:gap-1">
              <ParagraphSmall>
                {t('subscription_six_month')}
                <span className="text-[#ED6E33] text-sm font-bold">
                  {' '}
                  {t('get_discount')}
                </span>
              </ParagraphSmall>
            </div>
          </Checkbox>
        </div>
        {isPhoneSize ? (
          <div className="w-[350px]">
            <div className="top-[68px] sticky z-9 h-[240px] bg-white pt-3">
              <Tabs
                fill="fixed"
                activeKey={activeTabKey}
                onChange={handleChangeTab}
                activateOnFocus
                overrides={{
                  TabList: {
                    style: {
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: '#F7F7F7',
                      borderRadius: '9999px',
                      padding: '4px',
                      width: '100%',
                    },
                  },
                  TabBorder: {
                    style: {
                      display: 'none',
                    },
                  },
                  TabHighlight: {
                    style: {
                      display: 'none',
                    },
                  },
                }}
              >
                {plans.map((plan) => (
                  <Tab
                    title={t(plan.type)}
                    overrides={{
                      Tab: {
                        style: ({ $isActive }) => ({
                          flex: 1,
                          textAlign: 'center',
                          padding: '8px 16px',
                          borderRadius: '9999px',
                          backgroundColor: $isActive ? '#FFFFFF' : '#F3F3F3',
                          color: $isActive ? '#276EF1' : '#4B4B4B',
                          fontWeight: $isActive ? 'bold' : 'normal',
                          fontSize: '14px',
                          cursor: 'pointer',
                          transition: 'all 0.2s ease-in-out',
                          ':hover': {
                            backgroundColor: $isActive ? '#FFFFFF' : '#E0E0E0',
                          },
                        }),
                      },
                      TabPanel: {
                        style: {
                          padding: '0px',
                        },
                      },
                    }}
                  >
                    <div className="mt-6">
                      <HeadingSmall color="#276EF1" className="text-center">
                        {t(plan.type)}
                      </HeadingSmall>
                      <div className="items-center">
                        {plan.type === 'enterprise' ? (
                          <div className="h-[96px] py-3 px-4 flex flex-col items-center">
                            <HeadingXSmall color={'#A6A6A6'}>
                              {t('talk_to_us')}
                            </HeadingXSmall>
                          </div>
                        ) : (
                          <div className="h-[96px] py-3 px-4 flex flex-col items-center">
                            <div className="flex items-end gap-2">
                              {plan.type === 'free' ? (
                                <HeadingSmall>Rp0</HeadingSmall>
                              ) : (
                                <HeadingSmall>
                                  Rp
                                  <span>
                                    {price?.toLocaleString('id-ID')}.000
                                  </span>
                                </HeadingSmall>
                              )}
                              <LabelXSmall color="#A6A6A6" className="mb-1">
                                /{t('month')}
                              </LabelXSmall>
                            </div>
                            {plan.type === 'basic' && (
                              <ParagraphXSmall
                                color="#5E5E5E"
                                className="!text-[10px] text-center mt-2"
                              >
                                {`${t('exclude_ppn')} ${t('price_can_change')}`}
                              </ParagraphXSmall>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col items-center gap-4 mb-[18px]">
                        {plan.type === 'free' && (
                          <NavLink to={`${config.AUTH_URL}/register`}>
                            <Button size={SIZE.compact}>{t('register')}</Button>
                          </NavLink>
                        )}
                        {plan.type !== 'free' && (
                          <a
                            href={getGrettingMessage(plan.type)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button size={SIZE.compact}>
                              {t('contact_us')}
                            </Button>
                          </a>
                        )}
                      </div>
                    </div>
                  </Tab>
                ))}
              </Tabs>
            </div>
            {plans
              ?.find((_, index) => index.toString() === activeTabKey)
              ?.subscriptions.map((indicator, index) => (
                <div
                  key={index}
                  className={
                    'border-b border-solid border-[#E6E9EB] p-4 w-full'
                  }
                >
                  <div className="flex justify-between">
                    <ParagraphSmall>{indicator.name}</ParagraphSmall>
                    {indicator.label ? (
                      <ParagraphSmall>{indicator.label}</ParagraphSmall>
                    ) : indicator.isInclude ? (
                      <Check size={24} color="#3AA76D" />
                    ) : (
                      <Delete size={24} color="#EB7567" />
                    )}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="flex items-end mt-4">
            <div className="w-[299px]">
              <div className="flex pb-4 ml-3">
                <Checkbox
                  checked={isShowDifferent}
                  labelPlacement={LABEL_PLACEMENT.right}
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        alignItems: 'center',
                        justifyContent: 'center',
                      }),
                    },
                  }}
                  onChange={handleChangeShowDifferent}
                >
                  <LabelSmall>{t('show_different_plan')}</LabelSmall>
                </Checkbox>
              </div>
              {subscriptions.map((indicator, index) => (
                <div
                  key={index}
                  className="p-4 border-b border-solid border-[#E6E9EB] h-[52px]"
                >
                  <ParagraphSmall>{indicator.name}</ParagraphSmall>
                </div>
              ))}
            </div>
            {plans.map((plan, index) => (
              <PlanItem
                key={index}
                type={plan.type}
                t={t}
                subscriptions={plan.subscriptions}
                price={price}
                whatsappLink={getGrettingMessage(plan.type)}
              />
            ))}
          </div>
        )}
      </main>
      <FooterLanding />
    </>
  );
}

export default withErrorBoundary(SubscriptionPage, {
  isPage: true,
});
