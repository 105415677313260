import { useDebounce, withErrorBoundary } from '@navi-app/utils';
import L, { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { memo, useCallback, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { TLatLong, TMapHooks, TMaps } from './types/maps';

const pointerIcon = new Icon({
  iconUrl:
    'https://s3.ap-southeast-1.amazonaws.com/waresix.com/assets/navi/images/MapPin.svg',
  iconSize: [38, 38], // size of the icon
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

const infoPoint = new L.DivIcon({
  iconAnchor: [25, 60], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  html: ReactDOMServer.renderToString(
    <div
      style={{
        color: 'white',
        marginLeft: '-60px',
        paddingBottom: '90px',
        backgroundColor: '#03222F',
        display: 'flex',
        width: '180px',
        borderRadius: '4px',
        padding: '8px',
        paddingRight: '8px',
      }}
    >
      Geser peta untuk atur lokasi.
    </div>
  ),
});

function MapHookEvent({
  setlatLng,
  onSetMoved,
  currentLatLong,
  triggerFlyto,
  onDragEnd,
}: TMapHooks) {
  const map = useMapEvents({
    click: () => {},
    move: () => {
      const data = map.getCenter();
      setlatLng(data);
    },
    dragstart: () => {
      onSetMoved();
    },
    dragend: () => {
      const data = map.getCenter();
      setlatLng(data);
      if (onDragEnd) {
        onDragEnd();
      }
    },
  });

  useEffect(() => {
    if (triggerFlyto > 0) {
      onSetMoved();
      map.invalidateSize();
      map.flyTo(currentLatLong, map.getZoom(), { animate: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFlyto]);

  return null;
}

function Maps({
  isOpen,
  refetch,
  latlng,
  setlatLng,
  triggerFlyto,
  fromSearch,
  onDragEnd,
  setMapMoved,
  mapMoved,
}: TMaps) {
  const latlong: TLatLong = useDebounce(latlng, 1000) as TLatLong;

  useEffect(() => {
    if (mapMoved) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMoved, latlong?.lat, latlong?.lng]);

  const onSetMoved = useCallback(() => {
    if (!mapMoved) {
      if (setMapMoved) {
        setMapMoved(true);
      }
    }
  }, [mapMoved, setMapMoved]);

  return (
    <MapContainer
      style={{ height: '315px' }}
      markerZoomAnimation={true}
      zoomAnimation={true}
      fadeAnimation={true}
      center={[latlng?.lat, latlng?.lng]}
      zoom={30}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
      />
      <Marker position={[latlng.lat, latlng.lng]} icon={pointerIcon}></Marker>
      {!mapMoved && (
        <Marker position={[latlng.lat, latlng.lng]} icon={infoPoint}>
          {' '}
        </Marker>
      )}
      <MapHookEvent
        onDragEnd={onDragEnd}
        triggerFlyto={triggerFlyto}
        currentLatLong={latlng}
        setlatLng={setlatLng}
        onSetMoved={onSetMoved}
      />
    </MapContainer>
  );
}

export default memo(withErrorBoundary(Maps));
