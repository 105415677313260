/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikConfig, FormikValues, useFormik } from 'formik';

type TuseForm = {
  initialValues: any;
  validationSchema?: any;
  onSubmit: (values: any) => void;
};
const useForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  ...rest
}: TuseForm & FormikConfig<FormikValues>) => {
  const formik = useFormik({
    ...rest,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const errors: typeof initialValues = formik.errors;
  const handleSubmit: (event?: React.FormEvent<HTMLFormElement | any>) => void =
    formik.handleSubmit;
  return {
    ...formik,
    errors,
    handleSubmit,
  };
};

export default useForm;
