import { isEmpty } from '@navi-app/utils';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export type DynamicObject = {
  [name: string]: unknown;
};

type SecurityPolicy = {
  [key: string]: string[];
};

type TPropsMeta = {
  key?: string;
  title?: string;
  description?: string;
  keywords?: string;
  canonicalLink?: string;
  openGraph?: {
    title: string;
    description: string;
    image: string;
    url: string;
    type: 'article' | 'website';
    siteName?: string;
  };
  article?: {
    publishedTime: string;
    modifiedTime: string;
    tag: string;
    publisher?: string;
  };
  twitter?: {
    title: string;
    description: string;
    image: string;
    card: string;
    url?: string;
    site?: string;
  };
  structuredData?: DynamicObject;
  criticalImages?: string[];
  securityPolicy?: SecurityPolicy;
  config?: DynamicObject;
};

const convertSecurityPolicyToString = (
  policy: SecurityPolicy,
  config?: DynamicObject
): string => {
  if (!config) {
    return '';
  }
  const ALLOWED_URL = (
    config.IS_PRODUCTION_ENV
      ? [config.CSP_ALLOWED_URL, config.CSP_ALLOWED_SUB_URL]
      : [
          config.CSP_ALLOWED_URL,
          config.CSP_ALLOWED_SUB_URL,
          'http://localhost:*',
        ]
  ) as string[];

  const defaultPolicy: SecurityPolicy = {
    'default-src': ["'self'", ...ALLOWED_URL],
    'style-src': ["'self'", "'unsafe-inline'"],
    'img-src': [
      "'self'",
      'data:',
      'https://s3.ap-southeast-1.amazonaws.com',
      'https://sit-navi-storage.waresix.com',
      'https://assets.mobitech.id',
      ...ALLOWED_URL,
    ],
    'media-src': [
      "'self'",
      'data:',
      'https://s3.ap-southeast-1.amazonaws.com',
      'https://sit-navi-storage.waresix.com',
      'https://assets.mobitech.id',
    ],
    'font-src': ["'self'", 'data:'],
    'script-src': ['https://cdn.sentry.io'],
    'connect-src': [
      "'self'",
      'https://*.ingest.sentry.io',
      'https://*.ingest.us.sentry.io',
      'https://www.google-analytics.com',
      ...ALLOWED_URL,
    ],
    'worker-src': ["'self'", ...ALLOWED_URL],
    'script-src-elem': ['https://www.gstatic.com', ...ALLOWED_URL],
  };

  const mergedPolicy = { ...defaultPolicy };

  Object.entries(policy).forEach(([key, values]) => {
    if (mergedPolicy[key]) {
      mergedPolicy[key] = Array.from(
        new Set([...mergedPolicy[key], ...values])
      );
    } else {
      mergedPolicy[key] = values;
    }
  });

  return (
    Object.entries(mergedPolicy)
      .map(([key, values]) => `${key} ${values.join(' ')}`)
      .join('; ') + ';'
  );
};

// remove default tag on index.html
function removeDefaultTag(selector: string) {
  const metaTags = document.querySelectorAll(selector);
  if (metaTags.length > 1) {
    metaTags[0].remove();
  }
}

export default function Meta({
  key,
  title,
  description,
  canonicalLink,
  keywords,
  openGraph,
  article,
  twitter,
  structuredData,
  criticalImages,
  securityPolicy,
  config,
}: TPropsMeta) {
  useEffect(() => {
    if (config?.APP_NAME === 'brand' && !isEmpty(title)) {
      removeDefaultTag('title');
      removeDefaultTag("meta[name='description']");
      removeDefaultTag("link[rel='image_src']");
    }
  }, [config, title]);

  useEffect(() => {
    if (config?.APP_NAME === 'brand' && !isEmpty(openGraph)) {
      removeDefaultTag("meta[property='og:title']");
      removeDefaultTag("meta[property='og:description']");
      removeDefaultTag("meta[property='og:image']");
      removeDefaultTag("meta[property='og:url']");
      removeDefaultTag("meta[property='og:site_name']");
      removeDefaultTag("meta[property='og:type']");
    }
  }, [config, openGraph]);

  useEffect(() => {
    if (config?.APP_NAME === 'brand' && !isEmpty(twitter)) {
      removeDefaultTag("meta[name='twitter:card']");
      removeDefaultTag("meta[name='twitter:title']");
      removeDefaultTag("meta[name='twitter:description']");
      removeDefaultTag("meta[name='twitter:url']");
      removeDefaultTag("meta[name='twitter:image']");
    }
  }, [config, twitter]);

  useEffect(() => {
    setTimeout(() => {
      if (config?.APP_NAME === 'brand' && !isEmpty(structuredData)) {
        removeDefaultTag("script[type='application/ld+json']");
      }
    }, 1000);
  }, [structuredData]);

  return (
    <Helmet key={key}>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}

      {/* Critical Image */}
      {criticalImages &&
        criticalImages.map((image) => (
          <link rel="preload" href={image} as="image" />
        ))}

      {/* Structured Data */}
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}

      {/* Content Security Policy */}
      {securityPolicy && (
        <meta
          http-equiv="Content-Security-Policy"
          content={convertSecurityPolicyToString(securityPolicy, config)}
        />
      )}
      <link
        rel="image_src"
        href="https://assets.mobitech.id/images/mobitech-logo.webp"
      />

      {keywords && <meta name="keywords" content={keywords} />}
      {config?.IS_PRODUCTION_ENV ? (
        <meta name="robots" content="index, follow" />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}

      {/* OPEN GRAPH */}
      {openGraph && <meta property="og:title" content={openGraph.title} />}
      {openGraph && (
        <meta property="og:description" content={openGraph.description} />
      )}
      {openGraph && (
        <meta
          property="og:image"
          content={
            openGraph.image ||
            'https://assets.mobitech.id/images/mobitech-logo.webp'
          }
        />
      )}
      {openGraph && (
        <meta
          property="og:url"
          content={openGraph.url || 'https://www.mobitech.id'}
        />
      )}
      {openGraph && (
        <meta
          property="og:site_name"
          content={openGraph?.siteName || 'mobitech.id'}
        />
      )}
      {openGraph && (
        <meta property="og:type" content={openGraph?.type || 'website'} />
      )}

      {/* Article */}
      {article && (
        <meta
          property="article:published_time"
          content={article.publishedTime}
        />
      )}
      {article && (
        <meta
          property="article:modified_time"
          content={article.publishedTime}
        />
      )}
      {article && <meta property="article:tag" content={article.tag} />}
      {article && (
        <meta
          property="article:publisher"
          content={
            article?.publisher ||
            'https://www.facebook.com/people/Mobitechid/61560697193870'
          }
        />
      )}
      <meta name="author" content="Mobitech Id" data-rh="true" />

      {/* Twitter */}
      {twitter && (
        <meta
          name="twitter:card"
          content={twitter.card || 'summary_large_image'}
        />
      )}
      {twitter && <meta name="twitter:title" content={twitter.title} />}
      {twitter && (
        <meta name="twitter:description" content={twitter.description} />
      )}
      {twitter && (
        <meta
          name="twitter:url"
          content={twitter?.url || 'https://www.mobitech.id'}
        />
      )}
      {twitter && (
        <meta
          name="twitter:image"
          content={
            twitter.image ||
            'https://assets.mobitech.id/images/mobitech-logo.webp'
          }
        />
      )}
    </Helmet>
  );
}
