import { cn, useWindowSize, withErrorBoundary } from '@navi-app/utils';
import { Button, KIND } from 'baseui/button';
import { DisplayMedium, LabelLarge } from 'baseui/typography';
import { TFunction } from 'i18next';
import { NavLink } from 'react-router-dom';
import { layoutConfig } from '../../commons/constants/layout';
import useAnalyticEvent from '../hooks/use-analytic-event';

type TCallToAction = {
  t: TFunction;
};

const CallToAction = ({ t }: TCallToAction) => {
  const lang = window?.localStorage?.getItem('i18nextLng') || 'id';
  const { sendEvent } = useAnalyticEvent();
  const { isPhoneSize } = useWindowSize();

  return (
    <section
      className={cn(
        'flex flex-col place-items-center',
        isPhoneSize ? 'my-14' : 'my-40'
      )}
    >
      <LabelLarge
        color="#5E5E5E"
        className="mb-4 !font-semibold !tracking-widest"
      >
        {t('common:ready_to_start')}
      </LabelLarge>
      <DisplayMedium
        className="!text-[28px] !leading-9 lg:!text-[44px] lg:!leading-[52px] !font-semibold text-center mb-6 break-words max-w-full lg:w-2/3 px-8 md:px-0"
        as="h2"
      >
        {t('common:get_optimal_result')}
      </DisplayMedium>
      <div
        className={cn(
          'flex justify-center gap-4 w-full',
          isPhoneSize ? 'flex-col text-center px-8' : 'flex-row'
        )}
      >
        <NavLink to={layoutConfig.authRegisterUrl} title="Sign in">
          <Button kind={KIND.secondary} className="flex gap-1 w-full">
            <span className="!font-bold">{t('common:sign')}</span>
            {`- ${t('common:free')}`}
          </Button>
        </NavLink>
        <NavLink to={`/${lang}/demo`} title="Schedule Demo">
          <Button
            className="flex gap-1 w-full"
            onClick={() => sendEvent(t('common:schedule_demo'))}
          >
            <span className="!font-bold">{t('common:schedule_demo')}</span>
            {`- ${t('common:free')}`}
          </Button>
        </NavLink>
      </div>
    </section>
  );
};

export default withErrorBoundary(CallToAction);
