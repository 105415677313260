import { EmptyState, Meta } from '@navi-app/ui';
import { cn, withErrorBoundary } from '@navi-app/utils';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../commons/constants/config';
import { layoutConfig } from '../../commons/constants/layout';
import { TArticleItem } from '../../commons/types/article';
import LoadingState from './components/loading-state';
import useListArticle from './hooks/use-list-article';

const FooterLanding = lazy(
  () => import('../../commons/components/footer-landing')
);
const HeaderLanding = lazy(
  () => import('../../commons/components/heading-landing')
);
const SectionHighlightContent = lazy(
  () => import('./components/section-highlight-content')
);

const SectionNewsArticle = lazy(
  () => import('./components/section-news-article')
);

function ArticlePage() {
  const { t } = useTranslation(['common', 'article']);
  const {
    articles,
    handleNextPage,
    handlePreviousPage,
    isLoading,
    hightLightContent,
    isEmptyArticle,
    isCanNextPage,
    isCanPrevPage,
    isLoadingArticle,
  } = useListArticle();

  const isEmptyArticleAfterLoaded = !isLoading && isEmptyArticle;
  const lang = window?.localStorage?.getItem('i18nextLng') || 'id';

  const allArticle = [hightLightContent, ...articles];

  return (
    <>
      <Meta
        key="meta-article"
        title={t('article:meta.title')}
        description={t('article:meta.description')}
        keywords="pengiriman, order, aplikasi logistik"
        openGraph={{
          title: t('meta.title'),
          description: t('meta.description'),
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          type: 'website',
          url: window?.location?.href || config.BRAND_URL,
        }}
        twitter={{
          card: 'summary_large_image',
          description: t('meta.description'),
          title: t('meta.title'),
          image: 'https://assets.mobitech.id/images/mobitech-logo.webp',
          url: window?.location?.href || config.BRAND_URL,
        }}
        canonicalLink={`${config.BRAND_URL}/${lang}/article`}
        structuredData={
          isLoading || isEmptyArticle
            ? {}
            : {
                '@context': 'https://schema.org',
                '@type': 'ItemList',
                itemListElement: allArticle?.map(
                  (article: TArticleItem, index: number) => ({
                    '@type': 'ListItem',
                    position: index + 1,
                    item: {
                      '@type': 'Article',
                      headline: article?.title,
                      author: {
                        '@type': 'Person',
                        name: 'Mobitech ID',
                      },
                      datePublished: article?.publishedDate,
                      url: `${config.BRAND_URL}/${lang}/article/${article?.permalink}`,
                      image:
                        article?.headerImage ||
                        'https://assets.mobitech.id/images/mobitech-logo.webp',
                      description: article?.summaryText,
                    },
                  })
                ),
              }
        }
        config={config}
      />
      <Suspense>
        <HeaderLanding
          config={layoutConfig}
          isHasArticle={!isEmptyArticle}
          isLoadingArticle={isLoadingArticle}
        />
        <main
          className={cn(
            isEmptyArticleAfterLoaded ? '' : 'lg:px-[120px] lg:py-[60px] p-4'
          )}
        >
          {isLoading ? (
            <LoadingState />
          ) : isEmptyArticle ? (
            <EmptyState
              title={t('article:article_not_yet')}
              desc={t('article:article_not_yet_desc')}
              height="calc(100vh - 77px)"
            />
          ) : (
            <Suspense>
              <SectionHighlightContent t={t} data={hightLightContent} />
              <SectionNewsArticle
                t={t}
                articles={articles}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
                isCanNextPage={isCanNextPage}
                isCanPrevPage={isCanPrevPage}
              />
            </Suspense>
          )}
        </main>
        {!isEmptyArticleAfterLoaded && <FooterLanding />}
      </Suspense>
    </>
  );
}

export default withErrorBoundary(ArticlePage, {
  isPage: true,
});
