export const INDEXED_DB_CONFIG = {
  version: 3,
  dbName: 'mobitech_db',
  stores: {
    user: '@user-data-store',
    planning: '@order-planning-store',
    template: '@download-template-store',
  },
};

export const USER_ROLES_STORE = '@user-roles-store';
