import { useQuery } from 'react-query';
import { getListArticle } from '../../api';

export default function useCheckArticle() {
  const filter = {
    limit: 1,
    offset: 0,
    status: 'published',
  };
  const { data: dataArticle, isLoading: isLoadingArticle } = useQuery(
    ['get-list-article', filter],
    () => getListArticle(filter),
    {
      retry: false,
    }
  );

  const isHasArticle = dataArticle?.list && dataArticle?.list?.length > 0;

  return {
    isHasArticle,
    isLoadingArticle,
  };
}
