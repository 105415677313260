/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { USER_ROLES_STORE } from '../indexedDB/indexed-db-config';

type TData = {
  userRoles: string;
  organization: { id?: string; name?: string; address?: string; logo?: string };
};

type TUserRolesStore = {
  isPopulatedData: boolean;
  menuAccess: string[];
  setInitialData: (data: TData) => void;
  setMenuAccess: (val: string[]) => void;
  reset: () => void;
} & TData;

const useUserRolesStore = create<TUserRolesStore>()(
  persist(
    (set) => ({
      isPopulatedData: false,
      userRoles: '',
      organization: {},
      menuAccess: [],
      setInitialData: (val: TData) =>
        set(() => ({
          isPopulatedData: true,
          userRoles: val.userRoles,
          organization: val.organization,
        })),
      setMenuAccess: (menuAccess: string[]) => set(() => ({ menuAccess })),
      reset: () =>
        set(() => ({
          isPopulatedData: false,
          userRoles: '',
          organization: {},
        })),
    }),
    {
      name: USER_ROLES_STORE,
    }
  )
);

export default useUserRolesStore;
