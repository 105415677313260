import { cn, withErrorBoundary } from '@navi-app/utils';
import { ReactNode } from 'react';

export type TFooter = {
  customLeftContent?: ReactNode;
  customRightContent?: ReactNode;
  isFixedBottom?: boolean;
  className?: string;
  customMarginBottom?: string;
};

function Footer({
  customLeftContent,
  customRightContent,
  isFixedBottom = true,
  className,
}: TFooter) {
  return (
    <div
      className={cn(
        'bg-white bottom-0 border-t border-tertiaryB flex items-center w-full justify-between px-5 lg:px-10 py-4',
        isFixedBottom ? 'fixed' : 'relative',
        className
      )}
    >
      <div>{customLeftContent}</div>
      <div>{customRightContent}</div>
    </div>
  );
}

export default withErrorBoundary(Footer);
