import { removeAuthentication, useModalStore } from '@navi-app/utils';
import Cookies from 'js-cookie';
import { useShallow } from 'zustand/react/shallow';

export default function useUpdateVersion() {
  const { modalData, closeModal, modalName } = useModalStore(
    useShallow((state) => ({
      modalData: state.modalData,
      closeModal: state.closeModal,
      modalName: state.modalName,
    }))
  );
  const isOpen = modalName === 'modal-update-version';

  const onUpdateVersion = async () => {
    Cookies.set('latestVersion', modalData?.latestVersion || 'v1', {
      expires: 30,
    });
    if (modalData?.isLogin) {
      removeAuthentication(modalData?.domain);
      window?.location?.reload();
    } else {
      window?.location?.reload();
    }
  };
  return {
    onUpdateVersion,
    closeModal,
    isOpen,
  };
}
