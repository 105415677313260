import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const i18n = i18next.createInstance();

export const initI18n = (prefixPath: string, lang: string = 'id') => {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .init({
      fallbackLng: false,
      lng: lang || 'id',
      debug: true,
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${prefixPath}/locales/{{lng}}/{{ns}}.json`,
      },
    });
};

export const providerTranslationValue = i18n;
