import { useEffect, useMemo, useState } from 'react';

const determineScreenSize = (
  width: number
): 'large' | 'medium' | 'small' | 'extra small' => {
  if (width > 1040) {
    return 'large';
  } else if (width >= 768 && width <= 1040) {
    return 'medium';
  } else if (width >= 599 && width < 768) {
    return 'small';
  } else {
    return 'extra small';
  }
};

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    screenSize: determineScreenSize(window.innerWidth),
  });

  const isPhoneSize = useMemo(
    () => windowSize.width <= 599,
    [windowSize.width]
  );

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        screenSize: determineScreenSize(window.innerWidth),
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isPhoneSize,
    windowSize,
  };
}

export default useWindowSize;
