import { httpRequest } from '@navi-app/utils';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import config from './app/commons/constants/config';

import { initI18n, providerTranslationValue } from '@navi-app/utils';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, useLocation } from 'react-router-dom';
import App from './app/app';

httpRequest.initFetch(
  {
    baseURL: config.API_URL,
    timeout: 600000,
    headers: {},
  },
  {
    domainApp: config.DOMAIN_URL,
    authUrl: config.AUTH_URL,
  }
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const BaseApp = () => {
  const location = useLocation();
  const lang = location?.pathname?.split('/')[1];

  const language = lang !== 'en' && lang !== 'id' ? '' : lang || 'id';

  initI18n('', language);

  return <App />;
};

root.render(
  <StrictMode>
    <I18nextProvider i18n={providerTranslationValue}>
      <BrowserRouter>
        <BaseApp />
      </BrowserRouter>
    </I18nextProvider>
  </StrictMode>
);
