/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { withErrorBoundary } from '@navi-app/utils';
import { useStyletron, withStyle } from 'baseui';
import { Button, KIND } from 'baseui/button';
import { TriangleDown } from 'baseui/icon';
import { StatefulMenu, StyledEmptyState, StyledList } from 'baseui/menu';
import { Pagination } from 'baseui/pagination';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { Select, StyledDropdownListItem } from 'baseui/select';
import { LabelSmall } from 'baseui/typography';
import { Children, forwardRef, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { DEFAULT_LIMIT, DEFAULT_PAGE, OPTIONS_LIMIT } from './constants/table';
import { TPagination } from './types/table';

type TPropsPagination = {
  pagination?: TPagination;
  onPageChange?: (page: number) => void;
  onLimitChange?: (limit: number) => void;
};

const LIST_ITEM_HEIGHT = 36;
const EMPTY_LIST_HEIGHT = 48;
const MAX_LIST_HEIGHT = 200;

const ListItem = withStyle(StyledDropdownListItem, {
  paddingTop: 0,
  paddingBottom: 0,
  display: 'flex',
  alignItems: 'center',
});

const VirtualDropdown = forwardRef<HTMLUListElement, any>((props: any, ref) => {
  const children = Children.toArray(props.children);

  // @ts-ignore
  if (!children[0] || !children[0].props.item) {
    return (
      <StyledList $style={{ height: EMPTY_LIST_HEIGHT + 'px' }} ref={ref}>
        <StyledEmptyState
          // @ts-ignore
          {...children[0]?.props}
        />
      </StyledList>
    );
  }

  const height = Math.min(MAX_LIST_HEIGHT, children.length * LIST_ITEM_HEIGHT);

  return (
    <StyledList $style={{ height: height + 'px' }} ref={ref}>
      <ViewportList
        // @ts-ignore
        viewportRef={ref}
        items={children}
      >
        {(list) => {
          // @ts-ignore
          const { item, overrides, ...restChildProps } = list?.props || {};
          return (
            <ListItem
              key={item?.id}
              style={{
                boxSizing: 'border-box',
                height: LIST_ITEM_HEIGHT,
              }}
              {...restChildProps}
            >
              {item?.id}
            </ListItem>
          );
        }}
      </ViewportList>
    </StyledList>
  );
});

function PaginationTable({
  pagination,
  onPageChange,
  onLimitChange,
}: TPropsPagination) {
  const [css] = useStyletron();
  const [searchParams, setSearchParams] = useSearchParams();

  const [limit, setLimit] = useState(pagination?.limit || DEFAULT_LIMIT);
  const [page, setPage] = useState(pagination?.currentPage || DEFAULT_PAGE);
  const [totalPage, setTotalPage] = useState(pagination?.totalPage || 0);
  const [totalData, setTotalData] = useState(pagination?.total || 0);
  const [selectedPage, setSelectedPage] = useState([
    {
      label: '1',
      id: '1',
    },
  ]);

  const optionsPage = useMemo(() => {
    return Array.from({ length: totalPage }, (_, index) => ({
      label: (index + 1).toString(),
      id: (index + 1).toString(),
    }));
  }, [totalPage]);

  useEffect(() => {
    setSelectedPage([{ id: page?.toString(), label: page?.toString() }]);
  }, [page]);

  useEffect(() => {
    if (pagination?.total) setTotalData(pagination?.total);
  }, [pagination?.total]);

  useEffect(() => {
    if (!pagination?.totalPage) {
      setTotalPage(Math.ceil(totalData / limit));
    } else {
      setTotalPage(pagination?.totalPage);
    }
  }, [pagination, totalData, limit]);

  // Ensure that 'limit' and 'page' values from the URL are valid or set defaults
  // Update both the URL parameters and component state accordingly
  useEffect(() => {
    const urlLimit = searchParams.get('limit') || DEFAULT_LIMIT;
    const urlPage = searchParams.get('page') || DEFAULT_PAGE;

    const isValidLimit = OPTIONS_LIMIT.includes(Number(urlLimit));
    const isValidPage = Number(urlPage) <= totalPage;

    const currentLimit = isValidLimit ? Number(urlLimit) : DEFAULT_LIMIT;
    const currentPage = isValidPage ? Number(urlPage) : DEFAULT_PAGE;

    searchParams.set('limit', currentLimit.toString());
    searchParams.set('page', currentPage.toString());

    setTimeout(() => {
      setSearchParams(searchParams);
      setLimit(currentLimit);
      setPage(currentPage);
    }, 100);
  }, []);

  const handlePageChange = (nextPage: number) => {
    if (onPageChange) onPageChange(nextPage);
    searchParams.set('page', nextPage.toString());
    setSearchParams(searchParams);
    setPage(nextPage);
    if (nextPage < 1) {
      return;
    }
    if (nextPage > Math.ceil(totalPage / limit)) {
      return;
    }
  };

  const handleLimitChange = (nextLimit: number) => {
    if (onLimitChange) onLimitChange(nextLimit);
    searchParams.set('limit', nextLimit.toString());
    searchParams.set('page', DEFAULT_PAGE.toString());
    setSearchParams(searchParams);
    const nextPageNum = Math.ceil(totalPage / nextLimit);
    if (nextPageNum < page) {
      setLimit(nextLimit);
      setPage(nextPageNum);
    } else {
      setLimit(nextLimit);
    }
  };

  return (
    <div
      className={css({
        paddingTop: '8px',
        display: 'flex',
        justifyContent: 'space-between',
      })}
    >
      <div className="flex items-center gap-2">
        <LabelSmall>Baris per halaman</LabelSmall>
        <StatefulPopover
          popoverMargin={0}
          content={({ close }) => (
            <StatefulMenu
              items={OPTIONS_LIMIT.map((limit: number) => ({
                label: limit,
                value: limit,
              }))}
              onItemSelect={({ item }) => {
                handleLimitChange(item.label);
                close();
              }}
              overrides={{
                List: {
                  style: { width: '64px' },
                },
              }}
            />
          )}
          placement={PLACEMENT.bottom}
        >
          <Button
            kind={KIND.secondary}
            size="compact"
            endEnhancer={TriangleDown}
          >
            {limit}
          </Button>
        </StatefulPopover>
        <LabelSmall>dari total {totalData} data</LabelSmall>
      </div>

      <Pagination
        size="compact"
        numPages={totalPage}
        currentPage={page}
        onPageChange={({ nextPage }) => handlePageChange(nextPage)}
        labels={{
          prevButton: 'Sebelumnya',
          nextButton: 'Selanjutnya',
          preposition: 'dari',
        }}
        overrides={{
          PrevButton: {
            style: {
              display: page === 1 ? 'none' : 'flex',
            },
          },
          NextButton: {
            style: {
              display: page === totalPage ? 'none' : 'flex',
            },
          },
          MaxLabel: {
            style: ({ $theme }) => ({
              ...$theme.typography.LabelSmall,
            }),
          },
          Select: {
            props: {
              overrides: {
                ControlContainer: {
                  component: () => (
                    <Select
                      clearable={false}
                      size="compact"
                      options={optionsPage}
                      value={selectedPage}
                      labelKey="id"
                      valueKey="label"
                      noResultsMsg=" "
                      // @ts-ignore
                      onChange={({ value }) => handlePageChange(value[0].id)}
                      overrides={{
                        Dropdown: {
                          component: VirtualDropdown,
                        },
                      }}
                    />
                  ),
                },
                SingleValue: {
                  style: ({ $theme }: any) => ({
                    padding: 0,
                    marginTop: '4px',
                    marginRight: '8px',
                    color: $theme.colors.buttonTertiaryText,
                    ...$theme.typography.LabelSmall,
                  }),
                },
                SelectArrow: {
                  style: ({ $theme }: any) => ({
                    padding: 0,
                    color: $theme.colors.buttonTertiaryText,
                  }),
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

export default withErrorBoundary(PaginationTable);
