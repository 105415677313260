import Cookies from 'js-cookie';
import { encryptStorage } from './encrypt-storage';
import {
  INDEXED_DB_CONFIG,
  USER_ROLES_STORE,
} from './indexedDB/indexed-db-config';
import { indexedDBStorage } from './indexedDB/indexed-db-storage';

type TData = {
  authToken: string;
  refreshToken: string;
  userInitialed: string;
  eligibleToAccess: string;
  userRoleId?: string;
};

export const setUserInitialed = (
  value: string,
  domainApp: string = '.mobitech.id'
) => {
  Cookies.set('userInitialed', value, {
    domain: domainApp,
  });
};

export const setAuthentication = (
  data: TData,
  domainApp: string = '.mobitech.id'
) => {
  Cookies.set('authToken', data?.authToken, {
    domain: domainApp,
  });
  Cookies.set('refreshToken', data?.refreshToken, {
    domain: domainApp,
  });
  Cookies.set('eligibleToAccess', data?.eligibleToAccess, {
    domain: domainApp,
  });
  Cookies.set('userRoleId', data?.userRoleId || '', {
    domain: domainApp,
  });
  setUserInitialed('false', domainApp);
};

export const removeAuthentication = async (
  domainApp: string = '.mobitech.id'
) => {
  await indexedDBStorage({
    isDevelopmentEnv: false,
    storeName: INDEXED_DB_CONFIG.stores.user,
  }).clearAll();
  Cookies.remove('authToken', { domain: domainApp });
  Cookies.remove('refreshToken', { domain: domainApp });
  Cookies.remove('userInitialed', { domain: domainApp });
  Cookies.remove('eligibleToAccess', { domain: domainApp });
  Cookies.remove('userRoleId', { domain: domainApp });
  Cookies.remove('customerId', { domain: domainApp });
  Cookies.remove('menuAccess', { domain: domainApp });
  Cookies.remove('userSubscriptionStatus', { domain: domainApp });
  encryptStorage.removeItem('@user-access');
  encryptStorage.removeItem('@navi-user-access');
  encryptStorage.removeItem('@navi-user-profile');
  localStorage.removeItem(USER_ROLES_STORE);
};
