/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateStorage } from 'zustand/middleware';
import { encrypted } from '../encrypt-storage';
import { isEmpty } from '../utils';
import { INDEXED_DB_CONFIG } from './indexed-db-config';

export type TOptionsIdb = {
  isDevelopmentEnv: boolean;
  dbName?: string;
  storeName?: string;
  version?: number;
  dbConnection?: IDBDatabase;
};

let request = null as any;
let isLoading = false;

function deleteDatabase(dbName: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const deleteRequest = indexedDB.deleteDatabase(dbName);

    deleteRequest.onsuccess = function () {
      console.log(`Database ${dbName} deleted successfully.`);
      resolve();
    };
    deleteRequest.onerror = function (event) {
      //@ts-ignore
      console.log(`Error deleting database:`, event.target?.error?.message);
      reject(event);
    };
    deleteRequest.onblocked = function () {
      console.log(`Database ${dbName} delete operation blocked.`);
    };
  });
}

function createIndexedDB(opt: TOptionsIdb) {
  const DB_NAME = opt.dbName || INDEXED_DB_CONFIG.dbName;
  const STORE_NAME = opt.storeName || '';
  const isDevelopmentMode = opt.isDevelopmentEnv;

  const openDB = (fn: (db: IDBDatabase) => void) => {
    if (opt.dbConnection) {
      fn(opt.dbConnection);
    } else {
      const request = indexedDB.open(DB_NAME, INDEXED_DB_CONFIG.version);
      request.onsuccess = function (event) {
        fn(request.result);
        request.result.close();
      };
      request.onerror = function (event) {
        //@ts-ignore
        console.log('Error opening database:', event?.target?.error?.message);
      };
      request.onupgradeneeded = function () {
        const db = request.result;
        Object.keys(INDEXED_DB_CONFIG.stores).forEach((s: string) => {
          //@ts-ignore
          const tempStoreName = INDEXED_DB_CONFIG.stores[s];
          if (!db?.objectStoreNames?.contains(tempStoreName)) {
            db?.createObjectStore(tempStoreName, { autoIncrement: true });
          }
        });
        console.log('Database created successfully');
      };
    }
  };

  const storage: StateStorage & {
    init: () => Promise<any>;
    getAll: () => Promise<any>;
    clearAll: () => Promise<any>;
    setBulkItem: (val: any) => void;
  } = {
    init: async () => {
      return new Promise(async (resolve, reject) => {
        if (!request && !isLoading) {
          isLoading = true;

          try {
            await deleteDatabase(DB_NAME); // Delete the database first
          } catch (error) {
            console.log('Error deleting database:', error);
            reject(error);
            return;
          }

          request = indexedDB.open(DB_NAME, INDEXED_DB_CONFIG.version);

          let db: IDBDatabase;
          request.onupgradeneeded = function () {
            db = request.result;

            Object.keys(INDEXED_DB_CONFIG.stores).forEach((s: string) => {
              //@ts-ignore
              const tempStoreName = INDEXED_DB_CONFIG.stores[s];
              if (!db?.objectStoreNames?.contains(tempStoreName)) {
                db?.createObjectStore(tempStoreName, { autoIncrement: true });
              }
            });
            console.log('Database created successfully');
          };

          request.onsuccess = function () {
            isLoading = false;
            resolve(request.result);
            console.log('Database opened successfully');
          };

          request.onblocked = function (event: any) {
            isLoading = false;
            reject('Error onblocked database');
            //@ts-ignore
            console.log(
              'Error onblocked database:',
              event?.target?.error?.message
            );
          };

          request.onerror = function (event: any) {
            isLoading = false;
            //@ts-ignore
            console.log(
              'Error opening database:',
              event?.target?.error?.message
            );
            reject('Error opening database');
          };
        }
      });
    },
    getAll: async () => {
      return new Promise<Record<string, any>>((resolve, reject) => {
        if (!isLoading) {
          isLoading = true;
          openDB((db) => {
            const transaction = db.transaction([STORE_NAME], 'readonly');
            const store = transaction.objectStore(STORE_NAME);

            const cursorRequest = store.openCursor();
            const result: Record<string, any> = {};

            cursorRequest.onsuccess = function (event) {
              // @ts-ignore
              const cursor = event.target?.result;
              if (cursor) {
                if (!isDevelopmentMode) {
                  result[cursor.key] = JSON.parse(
                    encrypted.decryptValue(cursor.value)
                  );
                } else {
                  result[cursor.key] = cursor.value;
                }
                cursor.continue();
              } else {
                isLoading = false;
                return resolve(result);
              }
            };
            cursorRequest.onerror = function (event) {
              //@ts-ignore
              console.log('Error getAll ', event?.target?.error?.message);
              isLoading = false;
              reject(event);
            };
          });
        }
      });
    },
    getItem: async (name: string): Promise<any> => {
      return new Promise((resolve, reject) => {
        openDB((db) => {
          const transaction = db.transaction([STORE_NAME], 'readonly');
          const store = transaction.objectStore(STORE_NAME);
          const request = store.get(name);

          request.onsuccess = () => {
            if (!isDevelopmentMode && !isEmpty(request.result)) {
              const result = JSON.parse(
                encrypted.decryptValue(request.result as string)
              );
              return resolve(result);
            }
            return resolve(request.result);
          };
          request.onerror = (event) => {
            //@ts-ignore
            reject('IndexedDB get error:' + event?.target?.error?.message);
          };
        });
      });
    },
    setBulkItem: async (value: any): Promise<void> => {
      if (!isLoading) {
        isLoading = true;
        openDB((db: IDBDatabase) => {
          try {
            const transaction = db.transaction([STORE_NAME], 'readwrite');
            const store = transaction.objectStore(STORE_NAME);

            Object.keys(value).forEach((k) => {
              let storeValue = value[k];
              if (!isDevelopmentMode) {
                storeValue = encrypted.encryptValue(JSON.stringify(value[k]));
              }
              if (storeValue) {
                store.put(storeValue, k);
              }
            });

            transaction.oncomplete = () => {
              isLoading = false;
            };

            transaction.onerror = (event) => {
              isLoading = false;
              //@ts-ignore
              console.log('Error setBulkItem', event?.target?.error?.message);
            };
          } catch (err) {
            isLoading = false;
            console.log('Error setBulkItem', err);
          }
        });
      }
    },
    setItem: async (name: string, value: any): Promise<void> => {
      return new Promise((resolve, reject) => {
        openDB((db: IDBDatabase) => {
          try {
            const transaction = db.transaction([STORE_NAME], 'readwrite');
            const store = transaction.objectStore(STORE_NAME);
            let storeValue = value;
            if (!isDevelopmentMode) {
              storeValue = encrypted.encryptValue(JSON.stringify(value));
            }
            if (storeValue && name) {
              store.put(storeValue, name);

              transaction.oncomplete = () => {
                resolve();
              };

              transaction.onerror = (event) => {
                //@ts-ignore
                console.log('Error setItem', event?.target?.error?.message);
                reject(event);
              };
            }
          } catch (err) {
            reject(err);
          }
        });
      });
    },
    removeItem: async (name: string): Promise<void> => {
      console.log(name, 'has been deleted');
    },
    clearAll: async () => {
      return new Promise((resolve, reject) => {
        openDB((db: IDBDatabase) => {
          Object.keys(INDEXED_DB_CONFIG.stores).forEach((s: string) => {
            //@ts-ignore
            const tempStoreName = INDEXED_DB_CONFIG.stores[s];
            const transaction = db.transaction([tempStoreName], 'readwrite');
            const objStore = transaction.objectStore(tempStoreName);
            const request = objStore.clear();
            request.onsuccess = function () {
              resolve('success clear');
            };
            request.onerror = function (event) {
              //@ts-ignore
              console.log('Error clearAll', event.target?.error?.message);
              reject('failed clear');
            };
          });
        });
      });
    },
  };

  return storage;
}

export const indexedDBStorage = (opt: TOptionsIdb) => createIndexedDB(opt);
