import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { withErrorBoundary } from '@navi-app/utils';
import { HeaderNavigation } from 'baseui/header-navigation';
import { HeadingXSmall, ParagraphSmall } from 'baseui/typography';
import { memo, ReactNode } from 'react';
import TextEllipsis from '../text-ellipsis';

export type THeader = {
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  onBack?: () => void;
  config?: {
    mobiUrl?: string;
    optimaUrl?: string;
    domainApp?: string;
    apiUrl?: string;
  };
  customLeftContent?: ReactNode;
  customCenterContent?: ReactNode;
  customRightContent?: ReactNode;
  additonalLeftComponent?: ReactNode;
  isFixedTop?: boolean;
  customSubHeader?: ReactNode;
};

function Header({
  title,
  subTitle,
  onBack,
  customLeftContent,
  customCenterContent,
  customRightContent,
  additonalLeftComponent,
  isFixedTop = true,
  customSubHeader,
}: THeader) {
  return (
    <>
      <HeaderNavigation
        overrides={{
          Root: {
            style: {
              position: isFixedTop ? 'sticky' : 'relative',
              top: 0,
              height: '68px',
              zIndex: 1,
            },
          },
        }}
      >
        <div className="flex items-center w-full justify-between px-5 lg:px-10">
          <div>
            {customLeftContent || (
              <div className="flex items-center">
                <ArrowLeftIcon
                  className="w-6 h-6 cursor-pointer"
                  onClick={onBack}
                />
                <div>
                  {typeof title === 'string' ? (
                    <HeadingXSmall className="ml-4">{title}</HeadingXSmall>
                  ) : (
                    title
                  )}
                  {typeof subTitle === 'string' ? (
                    <div className="max-w-[300px] ml-4">
                      <TextEllipsis
                        line={1}
                        propsTooltip={{ content: subTitle }}
                      >
                        <ParagraphSmall>{subTitle}</ParagraphSmall>
                      </TextEllipsis>
                    </div>
                  ) : (
                    subTitle
                  )}
                </div>
                {additonalLeftComponent}
              </div>
            )}
          </div>
          {customCenterContent && <div>{customCenterContent}</div>}
          <div>{customRightContent}</div>
        </div>
      </HeaderNavigation>

      {customSubHeader && (
        <div className="py-4 px-4 sm:py-6 sm:px-10 h-auto custom-scroll-select border-b-[1px] border-b-solid border-b-[#E8E8E8] overflow-hidden">
          {customSubHeader}
        </div>
      )}
    </>
  );
}

export default withErrorBoundary(memo(Header));
