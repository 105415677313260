/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';

export type DynamicObject = {
  [name: string]: any;
};

type initModalState = {
  modalName: string;
  subModalName: string;
  openModal: (value: string, data?: any) => void;
  openSubModal: (value: string) => void;
  closeModal: () => void;
  closeSubModal: () => void;
  modalData: DynamicObject;
  subModalData: DynamicObject;
  setModalData: (value: DynamicObject) => void;
  setSubModalData: (value: DynamicObject) => void;
};

const useModalStore = create<initModalState>((set) => ({
  modalName: '',
  subModalName: '',
  openModal: (value, data) =>
    set((state) => ({
      modalName: value,
      modalData: data || state.modalData || {},
    })),
  openSubModal: (value) => set(() => ({ subModalName: value })),
  closeModal: () => set(() => ({ modalName: '', modalData: {} })),
  closeSubModal: () => set(() => ({ subModalName: '', subModalData: {} })),
  modalData: {},
  subModalData: {},
  setModalData: (value) => set(() => ({ modalData: value })),
  setSubModalData: (value) => set(() => ({ subModalData: value })),
}));

export default useModalStore;
